import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BlackbirdStaticpageResponse } from '@ed-clients/shared';

import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ClientEnvironment, ENVIRONMENT } from '../common-injection-token';
import { Content } from './content.interface';
import { MetaInfoService } from './meta-info.service';


@Injectable( {
  providedIn: 'root'
} )
export class ContentResolverWenzelService implements Resolve<Content> {

  constructor(
    @Inject( PLATFORM_ID ) private platformId: string,
    private http: HttpClient,
    private metaInfo: MetaInfoService,
    @Inject( ENVIRONMENT ) private environment: ClientEnvironment
  ) {
  }

  resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<Content> {
    let envData;
    if ( !route.data.environment ) {
      envData = this.environment;
    } else {
      envData = route.data.environment;
    }

    if(route.data.content.queryString !== '' && !route.data.content.queryString) {
      // can use '' for queryString to get image string
      route.data.content.queryString = '?images=objects'
    }

    let params: string | number;
    if ( route.params.slug ) {
      params = `-${ route.params.slug }`;
    } else {
      params = '';
    }

    const slug = envData.blackbird.projectSlug + '-' + route.data.content.slug + params;
    const queryString = route.data.content.queryString;

    return this.http.get<BlackbirdStaticpageResponse>( this.getUrl( envData, slug, queryString ) )
      .pipe(
        map( data => data.data ),
        tap( data => {
          // console.log('from Content Blackbird', data);
          this.metaInfo.updateMetaInfos( data, envData.browser.host );
        } ),
        catchError( ( err ) => {
          if ( err.status === 404 ) {
            console.error( `Blackbird staticpage ${ err.url } doesn't exist or is inavtive` );
          }
          console.log( 'Blackbird error: ', err );
          return EMPTY;
        } )
      );
  }

  private getUrl( env, slug: string, queryString: string ): string {
    let host = env.browser.jsonHost;
    if ( isPlatformServer( this.platformId ) ) {
      host = env.ssr.jsonHost;
    }
    return host + `/${ env.blackbird.content }/${ slug }` + queryString;
  }
}
