import {BlackbirdImage} from './blackbird.interface';
import {Schema, Seo, SocialMedia} from '../seo/meta.interface';

export interface Content {
  schema?: Schema;
  schemas: Schema[];
  seo: Seo;
  socialMedia: SocialMedia;
  mainTitle?: string;
  mainSubTitle?: string;
  mainDescription?: string;
  mainImage?: BlackbirdImage | string;
  mainSectionHeading?: string;
  mainSectionDescription?: string;
  mainSectionImage?: BlackbirdImage | string;
}


