import { isPlatformServer } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ClientEnvironment, ENVIRONMENT, SwStoreResponse } from '@ed-clients/shared';
import { map } from 'rxjs/operators';

import { Reference, ReferencesCategory } from './project-references.interface';

@Injectable ( {
    providedIn: 'root',
} )
export class ProjectReferencesService {

    constructor(
        private http: HttpClient,
        @Inject ( ENVIRONMENT ) private environment: ClientEnvironment,
        @Inject ( PLATFORM_ID ) private platformId,
    ) {
    }

    getReferencesUrl( isCategories = false ): string {
        let host = this.environment.browser.shopHost;
        if ( isPlatformServer ( this.platformId ) ) {
            host = this.environment.ssr.shopHost;
        }

        if ( isCategories ) {
            return host + this.environment.shopware.categories;
        } else {
            return host + this.environment.shopware.products;
        }
    }

    getReferences( action: { categoryId?: string; page?: number; limit?: number } ) {
        const body    = {
            includes    : {
                product      : ['id',
                                'name',
                                'media',
                                'categories',
                                'cover',
                                'customFields',
                                'description',
                                'calculatedPrice',
                                'markAsTopseller'
                ], // 'createdAt', 'productNumber'
                product_media: ['media'], // cover, media's url
                // media        : ['url'], // cover, media's url,
                category     : ['id',
                                'name',
                ],
            },
            sort        : [ //  sort must come first before filter!!!
                { field: 'createdAt', order: 'DESC' },
                { field: 'productNumber', order: 'DESC', naturalSorting: true }, // useful when there are cloned references
            ],
            filter      : [
                { type: 'equals', field: 'vendor.id', value: this.environment.shopware.vendorId },
            ],
            associations: {
                media     : [],
                categories: [],
            },
        };
        const options = { headers: { 'sw-access-key': this.environment.shopware.referencesAccessKey } };

        if ( action.categoryId ) {
            body.filter.push (
                {
                    type : 'equalsAny',
                    field: 'categoryTree',
                    value: action.categoryId,
                },
            );
        }

        if ( action.page && action.page > 0 && action.limit && action.limit > 0 ) { // page and limit must coexist
            body[ 'page' ]             = action.page; // /store-api/product is used, thus page is the right name for the key
            body[ 'limit' ]            = action.limit;
            body[ 'total-count-mode' ] = 1; // get total number of searched references
        }

        return this.http.post<SwStoreResponse<Reference>> ( this.getReferencesUrl (), body, options )
                   .pipe (
                       map ( swRes => swRes ),
                   );
    }

    getCategories() {
        const body    = {
            includes    : {
                category      : ['name',
                                 'id',
                                 'media',
                                 'description',
                ],
                category_media: ['media'],
                media         : ['url'],
            },
            sort        : [
                { field: 'createdAt', order: 'ASC' },
            ],
            filter      : [
                { type: 'equals', field: 'parentId', value: this.environment.shopware.referencesCategoryId },
                { type: 'equals', field: 'active', value: true },
                { type: 'equals', field: 'vendorId', value: this.environment.shopware.vendorId },
            ],
            associations: {
                media: [],
            },
        };
        const options = { headers: { 'sw-access-key': this.environment.shopware.referencesAccessKey } };

        return this.http.post<SwStoreResponse<ReferencesCategory>> ( this.getReferencesUrl ( true ), body, options )
                   .pipe (
                       map ( swRes => swRes.elements ),
                   );
    }
}
