import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Reference, REFERENCES_LOAD, selectProjectReferences } from '@ed-clients/common-store';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

// Note: use postCfg to set pagination whenever lazy-loading is needed

@Injectable ( {
    providedIn: 'root',
} )
export class ReferencesResolver implements Resolve<Reference[]> {
    loaded = false; // this boolean prevents the store from dispatching again and again
    count  = 0; // this count prevents the store from dispatching again and again

    constructor(
        protected store: Store,
    ) {
    }

    // return empty array if no reference available
    resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<Reference[]> {

        return this.store.select ( selectProjectReferences )
                   .pipe (
                       tap ( reference => {
                           this.count ++; // count = 2 means loaded
                           if ( reference.length === 0 && !this.loaded && this.count < 2 ) {
                               const { categoryId, page, limit } = route.data.postCfg || {};
                               this.store.dispatch ( REFERENCES_LOAD ( { categoryId, page, limit } ) );
                           } else {
                               this.loaded = true;
                           }
                       } ),
                       filter ( () => this.loaded ),
                       take ( 1 ),
                   );
    }
}
