import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClientEnvironment, ENVIRONMENT } from '@ed-clients/shared';

import { BlackbirdConfig } from './blackbird-config.interface';

@Injectable ( {
  providedIn: 'root'
} )
export class BlackbirdConfigService {
  token = this.environment.blackbird.token;
  headers: HttpHeaders = new HttpHeaders();

  constructor (
    private http: HttpClient,
    @Inject ( ENVIRONMENT ) private environment: ClientEnvironment,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    this.headers = this.headers.set('Accept', 'application/json');
    this.headers = this.headers.set('authorization', 'Bearer ' + this.token);
  }

  getUrl(): string {
    let host = this.environment.browser.jsonHost;
    if (isPlatformServer(this.platformId)) {
      host = this.environment.ssr.jsonHost;
    }
    return host + `${this.environment.blackbird.content}/${this.environment.blackbird.projectSlug}-premium-config?images=object`;
  }

  getBlackbirdConfig() {
    return this.http.get<{ success: boolean, data: BlackbirdConfig }>(this.getUrl(), {
      headers: this.headers
    })
  }
}
