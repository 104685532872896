import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { interval, Observable, of } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { filter, first, mapTo } from 'rxjs/operators';

declare global {
  interface Window { google: {maps?: unknown}; }
}

@Injectable({
  providedIn: 'root'
})
export class LazyLoadGoogleMapResolver implements Resolve<boolean> {
  constructor ( @Inject ( PLATFORM_ID ) private platformId ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let counter = 0;
    let googleApiKey = 'AIzaSyAGO22Cr41iz_9_VobkmxxfaSgR8UKWXWg'; // default api key is used

    if( route.data.googleApiKey ) {
      googleApiKey = route.data.googleApiKey;
    }

    if ( isPlatformBrowser ( this.platformId ) ) {
      if ( document.querySelector('mapsScript') || ( window.google && window.google.maps && Object.keys( window.google.maps ).length > 0 ) ) {
        // console.log ( 'window.google.maps already exist' );
        return of ( true );
      } else {
        const script = document.createElement('script');
        script.id = 'mapsScript';
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}`;
        document.body.appendChild( script );
        return interval( 100 ).pipe(
          filter ( v =>  ++counter > 50 || window.google && window.google.maps && Object.keys( window.google.maps ).length > 0  ),
          // tap ( v => console.log ( counter, window.google && window.google.maps && Object.keys( window.google.maps ).length > 0 ) ),
          first (),
          mapTo ( true )
        )
      }
    } else {
      // console.log ( 'SSR - do not wait on - window.google.maps already' );
      return of ( true )
    }
  }
}
