import { Pipe, PipeTransform } from '@angular/core';

// features =>
// 1. The last word of truncated string is always in whole word (the last text is not separated nor incomplete).
// 2. Seo friendly => https://www.semrush.com/blog/html-hide-element/
// 3. Desired number of limited characters can be set (including HTML Tags and empty spaces)

// prerequisites =>
// 1. This pipe must be used inside innerHTML or equivalent. Support html tags only
// 2. Only works for string wrapped inside <div></div>. E.g. text area from blackbird admin portal

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform { // eslint-disable-line
  transform(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (value.length <= limit || completeWords) {
      return value;
    } else {
      const lastIndexOfEmptySpaceOfGivenIndex = value.lastIndexOf(' ', limit); // limit index is inclusive
      // limit is the fromIndex, the method going to start seaching from. And It is seaching BACKWARDS!

      let limitedString: string;
      let hiddenString: string;

      if(lastIndexOfEmptySpaceOfGivenIndex === -1) { // in case of no ' ' found in the given index and backwards, then take the first ' ' encountered beyond the given index
        const firstIndexOfEmptySpace = value.indexOf(' ');
        if(firstIndexOfEmptySpace === -1) { // no even single ' ' in the value string
          limitedString = value; // Take the whole string then => e.g. <div>abcdefgh</div> with limit index 8
          hiddenString = '';
        } else {
          limitedString = value.substring(0, firstIndexOfEmptySpace); // e.g. <div>abcdef gh</div> with limit index 10 => <div>abcdef
          // hiddenString = `<span hidden>${value.substring(firstIndexOfEmptySpace, value.lastIndexOf('</div>'))}</span>${ellipsis}</div>`;
          hiddenString = `${ellipsis}</div><span hidden>${value}</span>`;
        }
      } else {
        limitedString = value.substring(0, lastIndexOfEmptySpaceOfGivenIndex);
        // hiddenString = `<span hidden>${value.substring(lastIndexOfEmptySpaceOfGivenIndex, value.lastIndexOf('</div>'))}</span>${ellipsis}</div>`;
        hiddenString = `${ellipsis}</div><span hidden>${value}</span>`;
      }

      return limitedString + hiddenString;
    }
  }
}
