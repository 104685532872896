import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import {
    REFERENCES_CATEGORIES_LOAD,
    REFERENCES_CATEGORIES_SET,
    REFERENCES_LAZY_LOADED_SET,
    REFERENCES_LOAD,
    REFERENCES_SET,
    REFERENCES_TOTAL_SET,
} from './project-references.actions';
import { ProjectReferencesService } from './project-references.service';

@Injectable()
export class ProjectReferencesEffectsService {

  constructor(
    private actions: Actions,
    private referencesService: ProjectReferencesService
  ) { }

  loadReferences = createEffect( () => this.actions.pipe(
    ofType( REFERENCES_LOAD ),
    mergeMap( (action) => this.referencesService.getReferences(action).pipe(
      mergeMap( ( res ) => {
        const references = action.page? res.elements.map( element => {
          element.page = action.page;
          return element;
        } ) : res.elements;

        if( (!action.page && !action.limit) ) { // if first page or page & limit undefined
          return [
            REFERENCES_SET({references}),
            REFERENCES_TOTAL_SET({total:res.total})
          ];
        } else {
            return [ // non first page
                REFERENCES_LAZY_LOADED_SET({references}),
                REFERENCES_TOTAL_SET({total:res.total})
            ]
        }
      } )
    ) )
  ), { dispatch: true } );

  loadReferencesCategories = createEffect( () => this.actions.pipe(
    ofType( REFERENCES_CATEGORIES_LOAD ),
    mergeMap( () => this.referencesService.getCategories().pipe(
      map( ( res ) => {
        return REFERENCES_CATEGORIES_SET({categories:res});
      } )
    ) )
  ), { dispatch: true } );
}
