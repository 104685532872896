import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';

import { PROJECTS_LAZY_LOADED_SET, PROJECTS_LOAD, PROJECTS_SET, PROJECTS_SET_Pagination } from './projects.actions';
import { ProjectsService } from './projects.service';

@Injectable()
export class ProjectsEffectsService {

  constructor(
    private actions: Actions,
    private projectsService: ProjectsService
  ) {
  }

  loadProjects = createEffect( () => this.actions.pipe(
    ofType( PROJECTS_LOAD ),
    mergeMap( action => this.projectsService.getProjects( action ).pipe(
      mergeMap( ( res ) => {
        const projects = action.page? res.data.map( element => {
          element.page = action.page;
          return element;
        } ) : res.data;

        if( action.page === 1 || (!action.page && !action.limit) ) { // if first page or page & limit undefined
          return [
            PROJECTS_SET( { projects } ),
            PROJECTS_SET_Pagination( { pagination: res.pagination } )
          ];
        } else return [ // non first page
          PROJECTS_LAZY_LOADED_SET( { projects } )
        ]
      } )
    ) )
  ), { dispatch: true } );
}
