export interface BlackbirdConfig {
  // mandatory fields
  defaultDescription: string;
  defaultSocialMediaImage: string;
  defaultTitle: string;

  // general shared Data from Blackbird
  addressText?: string;
  callNumText?: string;
  emailText?: string;
  faxNumText?: string;
  mediaFBLink?: string;

  // these fields are reserved and used only on frontend side, should NOT be the form fields of blackbird backend service
  callNumValue?: string;
  faxNumValue?: string;
}
