import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FAQ_LOAD, FaqService, selectFaq } from '@ed-clients/common-store';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BlackbirdPost } from './blackbird.interface';
import { resolvePosts } from './resolve-posts.fnc';

// Note: use postCfg to set pagination whenever lazy-loading is needed
// Note2: use searchTerm as param for faq search results page

@Injectable ( {
  providedIn: 'root'
} )
export class FaqsResolver implements Resolve<BlackbirdPost[]> {
  loaded = false; // this boolean prevents the store from dispatching again and again when there are no faq in Blackbird
  count  = 0; // this count prevents the store from dispatching again and again when there are no faq in Blackbird

  constructor (
    protected store: Store,
    private faqService: FaqService
  ) {
  }

  // return empty array if no news available
  resolve ( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<BlackbirdPost[]> {
    if ( route.params.searchTerm ) {
      return this.faqService.getFAQ( { query: `&q=${route.params.searchTerm}` } ).pipe(
        map( blackbirdRes => blackbirdRes.data )
      )
    }

    return resolvePosts(this.store, selectFaq, FAQ_LOAD, route, state);
  }
}
