export class AppNavStatics {

  static navData = {
    desktop: [
      {
        popperData: [
          {
            routerLink: 'softwareentwicklung',
            imgContent: '/assets/image/icon-entwicklung.svg',
            title: 'Softwareentwicklung',
            className: 'color-nettrek'
          },
          {
            routerLink: 'digitalisierung',
            imgContent: '/assets/image/digitalisierung.svg',
            title: 'Digitalisierung',
            className: 'color-nettrek'
          },
          {
            routerLink: 'beratung',
            imgContent: '/assets/image/icon-beratung.svg',
            title: 'Beratung',
            className: 'color-nettrek'
          },
          {
            routerLink: 'design',
            imgContent: '/assets/image/icon-design.svg',
            title: 'Design & UX',
            className: 'color-nettrek'
          },
          {
            externalLink: 'https://e5-suite.de/',
            imgContent: '/assets/image/icon-eagle5.svg',
            title: 'E-Learning',
            className: 'color-nettrek'
          }
        ],
        title: 'Leistungen',
        routerLink: 'leistungen',
      },
      {
        popperData: null,
        routerLink: 'projekte',
        title: 'Projekte',
        className: 'color-nettrek'
      },
      {
        popperData: [
          {
            routerLink: 'unternehmenswerte',
            imgContent: '/assets/image/icon-company-values.svg',
            title: 'Unsere Werte',
            className: 'color-nettrek'
          }
        ],
        routerLink: 'unternehmen',
        title: 'Unternehmen',
        className: 'color-nettrek'
      },
      {
        popperData: null,
        routerLink: 'karriere',
        title: 'Karriere',
        className: 'color-nettrek'
      },
      {
        popperData: null,
        routerLink: 'news',
        title: 'News',
        className: 'color-nettrek'
      },
      {
        popperData: null,
        routerLink: 'kontakt',
        title: 'Kontakt',
        className: 'color-nettrek'
      }
    ],
    mobile: {
      sections: [
        {
          title: 'Leistungen',
          navs: [
            {
              routerLink: 'softwareentwicklung',
              imgContent: '/assets/image/icon-entwicklung.svg',
              title: 'Softwareentwicklung',
              className: 'color-nettrek'
            },
            {
              routerLink: 'digitalisierung',
              imgContent: '/assets/image/digitalisierung.svg',
              title: 'Digitalisierung',
              className: 'color-nettrek'
            },
            {
              routerLink: 'beratung',
              imgContent: '/assets/image/icon-beratung.svg',
              title: 'Beratung',
              className: 'color-nettrek'
            },
            {
              routerLink: 'design',
              imgContent: '/assets/image/icon-design.svg',
              title: 'Design & UX',
              className: 'color-nettrek'
            },
            {
              externalLink: 'https://e5-suite.de/',
              imgContent: '/assets/image/icon-eagle5.svg',
              title: 'E-Learning',
              className: 'color-nettrek'
            }
          ]
        },
        {
          title: 'Unternehmen',
          navs: [
            {
              routerLink: 'projekte',
              imgContent: '/assets/image/icon-projekte.svg',
              title: 'Projekte',
              className: 'color-nettrek'
            },
            {
              routerLink: 'unternehmen',
              imgContent: '/assets/image/nettrek-logo-separat.svg',
              title: 'Unternehmen',
              className: 'color-nettrek'
            },
            {
              routerLink: 'unternehmenswerte',
              imgContent: '/assets/image/icon-company-values.svg',
              title: 'Unsere Werte',
              className: 'color-nettrek'
            },
            {
              routerLink: 'karriere',
              imgContent: '/assets/image/icon-karriere.svg',
              title: 'Karriere',
              className: 'color-nettrek'
            },
            {
              routerLink: 'news',
              imgContent: '/assets/image/news.svg',
              title: 'News',
              className: 'color-nettrek'
            },
            {
              routerLink: 'kontakt',
              imgContent: '/assets/image/icon-kontakt.svg',
              title: 'Kontakt',
              className: 'color-nettrek'
            }
          ]
        }
      ]
    }
  };

}
