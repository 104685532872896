import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
  {path: 'kontakt', loadChildren: () => import('./kontakt/kontakt.module').then(m => m.KontaktModule)},


  {path: 'unternehmen', loadChildren: () => import('./unternehmen/unternehmen.module').then(m => m.UnternehmenModule)},
  {path: 'karriere', loadChildren: () => import('./karriere/karriere.module').then(m => m.KarriereModule)},
  {path: 'projekte', loadChildren: () => import('./projekte/projekte.module').then(m => m.ProjekteModule)},


  {path: 'beratung', loadChildren: () => import('./beratung/beratung.module').then(m => m.BeratungModule)},
  {path: 'digitalisierung', loadChildren: () => import('./digitalisierung/digitalisierung.module')
      .then(m => m.DigitalisierungModule)},
  {path: 'design', loadChildren: () => import('./design/design.module').then(m => m.DesignModule)},
  {
    path: 'softwareentwicklung', loadChildren: () => import('./softwareentwicklung/softwareentwicklung.module')
      .then(m => m.SoftwareentwicklungModule)
  },
  {
    path: 'weihnachtshaus', loadChildren: () => import('./weihnachtskarte/weihnachtskarte.module')
      .then(m => m.WeihnachtskarteModule)
  },

  {path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule)},
  {
    path: 'datenschutzerklaerung', loadChildren: () => import('./datenschutzerklaerung/datenschutzerklaerung.module')
      .then(m => m.DatenschutzerklaerungModule)
  },
  {path: 'impressum', loadChildren: () => import('./impressum/impressum.module').then(m => m.ImpressumModule)},
  {path: 'agb', loadChildren: () => import('./agb/agb.module').then(m => m.AgbModule)},
  {path: 'notfound', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)},
  { path: 'leistungen', loadChildren: () => import('./leistungen/leistungen.module').then(m => m.LeistungenModule) },
  { path: 'unternehmenswerte', loadChildren: () => import('./unternehmenswerte/unternehmenswerte.module').then(m => m.UnternehmenswerteModule) },
  {path: '**', redirectTo: 'notfound'}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,
      {
        useHash: false,
        initialNavigation: 'enabled',
        anchorScrolling: 'enabled',
        scrollOffset: [0, 100] // ,
        // enableTracing: true
      })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
