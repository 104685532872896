import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonNav} from '../interfaces/common-nav';

@Component({
    selector: 'nt-nav-link-item',
    templateUrl: './nav-link-item.component.html',
    styleUrls: ['./nav-link-item.component.scss']
})
export class NavLinkItemComponent {

    @Input() linkData: CommonNav;
    @Input() classToUse: string;
    @Output() navItemClicked: EventEmitter<void> = new EventEmitter();

    itemClicked() {
        this.navItemClicked.emit();
    }
}
