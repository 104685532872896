import git from '../../../../git-version.json';

export const environment = {
  production: true,
  version: (git || {raw: 'unknown'}).raw + '-stage',
  useReduxDevTools: false,
  ssr: {
    jsonHost: 'https://blackbird.staging.echtdorsten.nettrek.dev/',
    shopHost: 'https://shop.staging.echtdorsten.nettrek.dev/'
  },
  browser: {
    host: 'https://staging.nettrek.de/',
    jsonHost: 'https://blackbird.staging.echtdorsten.nettrek.dev/',
    shopHost: 'https://shop.staging.echtdorsten.nettrek.dev/'
  },
  seo: {
    suffix: ' | netTrek'
  },
  blackbird: {
    projectSlug: 'nettrek',
    content: 'ed-clients/staticpages',
    posts: 'ed-clients/posts',
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyIiwiZXhwIjoxOTEyNjcyNzExfQ.hC7DF04DM0yXTR2KyRDYMAT4H2Az7Baxig784eGSB7w',
    config: 'ed-clients/staticpages/nettrek-premium-config?images=object' // global data
  },
  shopware: {
    products: 'store-api/product',
    jobsAccessKey: 'SWSCRHBAOG9OQ0RDCMV1A3HZUQ',
    vendorId: '024d749624a4473385f8df4985511a83'
  },
  join: {
    //host: '/join-debug',
    host: 'https://join-jobs-backend.staging.echtdorsten.nettrek.dev',
    debug: true
  },
  email: {
    contact : 'https://blackbird.staging.echtdorsten.nettrek.dev/ed-clients/nettrek/kontakt'
  },
  noindex: true
};
