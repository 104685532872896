import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';

import { VIDEOS_LAZY_LOADED_SET, VIDEOS_LOAD, VIDEOS_SET, VIDEOS_SET_Pagination } from './videos.actions';
import { VideosService } from './videos.service';

@Injectable()
export class VideosEffectsService {

  constructor(
    private actions: Actions,
    private videosService: VideosService
  ) {
  }

  loadvideos = createEffect( () => this.actions.pipe(
    ofType( VIDEOS_LOAD ),
    mergeMap( action => this.videosService.getVideos( action ).pipe(
      mergeMap( ( res ) => {
        const videos = action.page? res.data.map( element => {
          element.page = action.page;
          return element;
        } ) : res.data;

        if( action.page === 1 || (!action.page && !action.limit) ) { // if first page or page & limit undefined
          return [
            VIDEOS_SET( { videos } ),
            VIDEOS_SET_Pagination( { pagination: res.pagination } )
          ];
        } else return [ // non first page
          VIDEOS_LAZY_LOADED_SET( { videos } )
        ]
      } )
    ) )
  ), { dispatch: true } );
}
