import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProjectReferencesEffectsService } from './project-references.effects.service';
import { projectReferencesReducer } from './project-references.reducer';
import { referencesFeatureKey } from './project-references.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature ( referencesFeatureKey, projectReferencesReducer ),
    EffectsModule.forFeature ( [ProjectReferencesEffectsService] ),
  ]
})
export class ProjectReferencesStoreModule { }
