import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ErrorCodeDetails } from './error-codes';
import { Store } from '@ngrx/store';
import { ERROR_RESET, ERROR_SET } from './_store/error.actions';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';
import { selectErrorActiveState } from './_store/error.selectors';
import { filter } from 'rxjs/operators';

@Injectable ( {
  providedIn: 'root'
} )
export class ErrorService {

  readonly fatalError: ErrorCodeDetails = {
    title      : 'Schwerwiegender Fehler',
    description: ``
  };
  private dialogRef: MatDialogRef<ErrorDialogComponent, any>;

  constructor ( private store: Store,
                public dialog: MatDialog,
                @Inject ( PLATFORM_ID ) public platformId: any
  ) {
    this.init ();
  }

  resetActiveError (): void {
    this.dialogRef = undefined;
    this.store.dispatch ( ERROR_RESET () );
  }

  setActiveError ( active: ErrorCodeDetails ): void {
    this.store.dispatch ( ERROR_SET ( { active } ) );
  }

  setCustomActiveError ( title: string, description: string = '' ): void {
    this.store.dispatch ( ERROR_SET ( { active: { title, description } } ) );
  }

  setFatalError ( error?: ErrorCodeDetails | string | undefined ): void {
    if ( error ) {
      if ( typeof error === 'string' ) {
        this.setCustomActiveError ( this.fatalError.title, error );
      } else {
        this.setCustomActiveError ( this.fatalError.title, error.description );
      }
    } else {
      this.setActiveError ( this.fatalError );
    }
  }

  getFatalErrorAction ( error?: ErrorCodeDetails | string ) {
    return ERROR_SET ( { active: { title: this.fatalError.title,
        description: (typeof error === 'string') ? error : error.description } } )
  }

  getClientMessage ( error: Error ): string {
    if ( isPlatformBrowser ( this.platformId ) && !!navigator && !navigator.onLine ) {
      return 'Sie sind nicht mit dem Internet Verbunden';
    }
    return error.message ? error.message : error.toString ();
  }

  getClientStack ( error: Error ): string {
    if ( isPlatformBrowser ( this.platformId ) && !!navigator && !navigator.onLine ) {
      return 'Sie sind nicht mit dem Internet Verbunden';
    }
    return error.stack;
  }

  getServerMessage ( error: HttpErrorResponse ): string {
    return error.message;
  }

  getServerStack ( error: HttpErrorResponse ): string {
    // handle stack trace
    return 'stack';
  }

  private openDialog ( data: ErrorCodeDetails ): void {
    if ( !this.dialogRef ) {
      this.dialogRef = this.dialog.open ( ErrorDialogComponent, {
        minWidth: '50%', maxWidth: '95%', maxHeight: '480px', data, disableClose: true
      } );
      this.dialogRef.componentInstance.num ++;
      this.dialogRef.afterClosed ()
          .subscribe ( result => {
            this.resetActiveError ();
          } );
    } else {
      this.dialogRef.componentInstance.num ++;
    }
  }

  private init () {
    // do not need to be unsubscribed
    this.store.select ( selectErrorActiveState )
        .pipe ( filter ( code => !!code ) )
        .subscribe ( code => this.openDialog ( code ) );
  }
}
