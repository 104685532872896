import {Component, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { delay, filter, tap } from 'rxjs/operators';

import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MetaInformationService } from '@ed-clients/shared';

@Component({
  selector: 'nt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public metaInfo: MetaInformationService,
              router: Router,
              private viewportScroller: ViewportScroller,
              private meta: Meta,
              @Inject(PLATFORM_ID) platformId: unknown) {
    if ( environment.noindex ) {
      this.meta.updateTag({name: 'robots', content: 'NOINDEX, NOFOLLOW'});
    }

    // by default, cookiebot doest support SPA. Cookie video is not loaded(accepted) after navigating back from other route/page.
    // This is because Cookie bot script is not loaded/executed to determine if cookie video should be loaded in SPA.
    // So runScripts is called again everytime there is a route change =>
    if(isPlatformBrowser(platformId)) {
      router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd) )
        .pipe(delay(1))   // this is to make sure runScripts() is executed in the next tick(logic is hoisted bottom/down) during runtime in browser. 1ms is enough.
        .subscribe(() => {
          window['Cookiebot']?.runScripts();
        });
    }

    // scrollPositionRestoration from angular router module is still buggy. Use following approach:
    router.events
      .pipe( filter((e): e is Scroll => e instanceof Scroll) )
      .pipe(delay(1))   // <--------------------------- This line
      .subscribe((e) => {
        if (e.position) {
          // backward navigation
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}
