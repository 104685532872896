import {DefaultUrlSerializer, UrlTree} from '@angular/router';

export class CustomUrlSerializer implements DefaultUrlSerializer {

    parse(url: string): UrlTree {
        const dus = new DefaultUrlSerializer();
        return dus.parse(url);
    }

    serialize(tree: UrlTree): string {
        const dus = new DefaultUrlSerializer();
        const path = dus.serialize(tree);

        const splitOn = path.indexOf('?') > -1 ? '?' : '#';
        const pathArr = path.split(splitOn);

        pathArr[0] += '/';

        // fix for the homepage url
        if (pathArr[0] === '//') {
            pathArr[0] = '/';
        }

        return pathArr.join(splitOn);
    }
}
