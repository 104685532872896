import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { JOBS_JOIN_APPLICATION, JOBS_JOIN_LOAD, JOBS_JOIN_SET } from './jobs-join.actions';

import { JobsJoinService } from './jobs-join.service';

@Injectable()
export class JobsJoinEffectsService {

  constructor(
    private actions: Actions,
    private jobsService: JobsJoinService
  ) { }

  loadJoinJobs = createEffect( () => this.actions.pipe(
    ofType( JOBS_JOIN_LOAD ),
    mergeMap( () => this.jobsService.getJobs().pipe(
      map( ( jobs ) => {
        return JOBS_JOIN_SET({jobs});
      } )
    ) )
  ), { dispatch: true } );

  submitJoinForm = createEffect( () => this.actions.pipe(
    ofType( JOBS_JOIN_APPLICATION ),
    mergeMap( (action) => this.jobsService.submitApplicationForm(action.form, action.otherFiles, action.id))
  ), { dispatch: false } );
}
