import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { errorFeatureKey } from './_store/error.selectors';
import { errorReducer } from './_store/error.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ErrorEffectsService } from './_store/error.effects.service';
import { MatDialogModule } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ServerErrorInterceptor } from './server-error-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from './global.error.handler';

@NgModule ( {
  declarations: [ErrorDialogComponent],
  imports     : [
    CommonModule,
    MatDialogModule,
    StoreModule.forFeature ( errorFeatureKey, errorReducer ),
    EffectsModule.forFeature ( [ErrorEffectsService] ),
    MatButtonModule,
    MatIconModule
  ],
  providers   : [
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
} )
export class ErrorModule {
}
