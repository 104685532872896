import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NewsStoreModule } from '@ed-clients/common-store';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import {
  ConfigStoreModule, DISPATCH_SERVER_ERROR_HANDLER, DispatchServerErrorHandler,
  ErrorModule
} from '@nettrek-angular-libs/common-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import { ProjectsEffectsService } from './_store/feature-store/projects-store/projects.effects.service';
import { projectsReducer } from './_store/feature-store/projects-store/projects.reducer';
import { projectsFeatureKey } from './_store/feature-store/projects-store/projects.selectors';

import { AppComponent } from './app.component';
import { AppNavModule } from './app-nav/app-nav.module';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { SsrModule } from '@nettrek-angular-libs/ssr';
import {
  BlackbirdInterceptorModule, CustomDialogErrorHandlerModule,
  ENVIRONMENT,
  LazyPictureModule
} from '@ed-clients/shared';

registerLocaleData(localeDE);

class customServerErrorMessage extends DispatchServerErrorHandler {
  override getServerMessage( error: HttpErrorResponse ): string {
    if(error.status === HttpStatusCode.PayloadTooLarge) {
      return error.error
    } else if (
      error.url.includes(environment.join.host + '/getJoinJobs')
    ) {
      return 'Join-Jobs wird aktualisiert. Bitte kommen Sie später wieder.'
    }

    return super.getServerMessage( error );
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    SsrModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    BlackbirdInterceptorModule.forRoot( environment.browser.jsonHost, environment.blackbird.token ),
    ConfigStoreModule.forRoot(environment.browser.jsonHost + environment.blackbird.config),
    NewsStoreModule,
    StoreModule.forFeature ( projectsFeatureKey, projectsReducer ),
    EffectsModule.forFeature ( [ProjectsEffectsService] ),
    ErrorModule.forRoot({
      allowOverridingGlobalErrorHandler: true,
      // Standart-Klasse die das darstellen des Fehlers und den RESET des Fehlers Steuert
      // showErrHandler: ShowErrorHandler,
      errorFeedbackMail: 'info@netTrek.de',
      // ignoreHttpErrForThisUrls: [
      //   // 'assets/mock/data.jsosn' // for Testing Only
      // ],
      // ignoreHttpStatusCodes: [ 404 ]
    }),
    CustomDialogErrorHandlerModule,
    ...(environment.useReduxDevTools ? [
      StoreDevtoolsModule.instrument({
        maxAge: 100, logOnly: !environment.useReduxDevTools,
        // wenn bestimmte Aktionen im Dev Tool nicht angezeigt werden sollen
      }),
    ] : []),
    HttpClientModule,
    NgxJsonLdModule,
    AppNavModule,
    LazyPictureModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de'},
    {provide: ENVIRONMENT, useValue: environment},
    {
      provide: DISPATCH_SERVER_ERROR_HANDLER, useClass: customServerErrorMessage, deps: [Store]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
