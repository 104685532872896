import { Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ed-clients-dynamic-paginator',
  templateUrl: './dynamic-paginator.component.html',
  styleUrls: ['./dynamic-paginator.component.scss']
})
export class DynamicPaginatorComponent implements OnChanges {
  @Input() arrayData = [];
  @Input() itemsPerPage: number;
  @Output() currentStartIndex = new EventEmitter<number>();
  storedStartIndex: number;
  @Output() currentEndIndex = new EventEmitter<number>();
  storedEndIndex: number;
  currentPage: number;
  grayOutPreviousButton: boolean;
  grayOutNextButton: boolean;

  ngOnChanges(): void {
    // ngonchanges needed here because api request is made to get data, arrayData is initially undefined
    // reset as inputs change and reinitialized (for dynamic arrayData)
    this.grayOutPreviousButton = true;
    this.grayOutNextButton = true;
    if( this.arrayData?.length > this.itemsPerPage ) {
      this.grayOutNextButton = false;
    }
    this.storedStartIndex = 0;
    this.storedEndIndex = this.itemsPerPage;
    this.currentPage = 1;
    // this.currentStartIndex.emit( this.storedStartIndex ); // dont put both emitter here => error
    // this.currentEndIndex.emit( this.storedEndIndex ); // reset these again in the parent component
  }

  nextPage(): void {
    if ( this.arrayData.length > this.itemsPerPage ) {
      // more than one page
      this.grayOutPreviousButton = false;
      if ( this.currentPage < Math.ceil( ( this.arrayData.length / this.itemsPerPage ) ) ) {
        this.storedStartIndex += this.itemsPerPage;
        this.currentStartIndex.emit( this.storedStartIndex );
        this.storedEndIndex += this.itemsPerPage;
        this.currentEndIndex.emit( this.storedEndIndex );
        this.currentPage++;
      }

      if ( this.currentPage === Math.ceil( ( this.arrayData.length / this.itemsPerPage ) ) ) {
        this.grayOutNextButton = true;
      }
    }
  }

  previousPage(): void {
    if ( this.arrayData.length > this.itemsPerPage ) {
      this.grayOutNextButton = false;
      if ( this.currentPage > 1 ) {
        this.storedStartIndex -= this.itemsPerPage;
        this.currentStartIndex.emit( this.storedStartIndex );
        this.storedEndIndex -= this.itemsPerPage;
        this.currentEndIndex.emit( this.storedEndIndex );
        this.currentPage--;
      }

      if ( this.currentPage === 1 ) {
        this.grayOutPreviousButton = true;
      }
    }
  }
}
