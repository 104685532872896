import { Schema, Seo, SocialMedia } from '../seo/meta.interface';
import { Content } from './content.interface';

export interface BlackbirdImage {
  uri: string,
  webp: string,
  alt?: string,
  title?: string,
  type: string
}

export interface BlackbirdStaticpageResponse {
  data: Content
}

export interface BlackbirdPostsResponse {
  pagination: BlackbirdPagination,
  data: BlackbirdPost[]
}

export interface BlackbirdPostResponse {
  data: BlackbirdPost
}

export interface BlackbirdPost {
  id : number;
  slug : string;
  page?: number;
  published: string;
  is_premium: boolean;
  is_echtdorsten: boolean;
  mainTitle: string;
  mainDescription: string;
  excerpt: string;
  mainImage: BlackbirdImage | string;
  category?: BlackbirdSelect;
  featured?: boolean;

  seo: Seo;
  schema: Schema;
  schemas: Schema[];
  socialMedia: SocialMedia;

  // customized fields
  is_toppost?: boolean;
  youtubeId?: string;
}

export interface BlackbirdPagination {
  page_count: number;
  current_page: number;
  has_next_page: boolean;
  has_prev_page: boolean;
  count: number;
  limit: number | null;
}

// blackbird sends values from <select> formfields as object
export interface BlackbirdSelect {
  value : string;
  label : string;
  uname? : string; // for subcategories
}

// blackbird configuration data
export interface BlackbirdConfig {
  // mandatory fields
  mainDescription: string;
  mainImage: BlackbirdImage;
  mainTitle: string;
  seo: Seo; // default seo data
  schemas: Schema[];
  socialMedia: SocialMedia;
  schema?: Schema; // could be deprecated in the future

  // general shared Data from Blackbird
  addressText?: string;
  callNumText?: string;
  emailText?: string;
  faxNumText?: string;
  mediaFBLink?: string;
  logo?: BlackbirdImage | string;
  mark?: string; // copyright

  // these fields are reserved and used only on frontend side, should NOT be the form fields of blackbird backend service
  callNumValue?: string; // deprecated, use pipe
  faxNumValue?: string; // deprecated, use pipe
}
