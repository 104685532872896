import {Params, RouterStateSnapshot} from '@angular/router';
import {RouterStateSerializer} from '@ngrx/router-store';

export interface RouterStateUrl {
  previousUrl?: string;
  url: string;
  // eslint-disable-next-line
  data?: any;
  params?: Params;
  queryParams?: Params;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  private previousUrl: string | undefined;
  private tempUrl: string | undefined;

  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    if ( routerState.url !== this.tempUrl ) {
      this.previousUrl = this.tempUrl;
      this.tempUrl = routerState.url;
    }

    let route = routerState.root;

    while ( route.firstChild ) {
      route = route.firstChild;
    }
    const {
      url,
      root: { queryParams },
    } = routerState;

    const { params, data } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { previousUrl: this.previousUrl, url, params, data, queryParams };
  }
}
