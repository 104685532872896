import { Component, Type, ComponentFactoryResolver, ViewChild, OnDestroy, ComponentRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { InsertionDirective } from './insertion.directive';
import { Subject } from 'rxjs';
import { DialogRef } from './dialog-ref';

// https://malcoded.com/posts/angular-dynamic-components/
// https://medium.com/@rado.sabo/angular-13-lazy-loading-of-module-for-dynamic-component-88952488eedf
// https://tutorialsforangular.com/2021/06/20/what-does-the-static-property-on-viewchild-do/
@Component({
  selector: 'ed-clients-light-weight-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;

  @ViewChild(InsertionDirective)
  insertionPoint: InsertionDirective;

  // private readonly _onClose = new Subject<any>();
  // public onClose = this._onClose.asObservable();

  childComponentType: Type<any>;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private cd: ChangeDetectorRef, private dialogRef: DialogRef) {}

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  // onOverlayClicked(evt: MouseEvent) {
  //   this.dialogRef.close();
  // }
  //
  // onDialogClicked(evt: MouseEvent) {
  //   evt.stopPropagation();
  // }

  loadChildComponent(componentType: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // close() {
  //   this._onClose.next();
  // }
}
