export interface JoinJob {
  id: number; // used
  createdAt: Date; // used
  lastUpdatedAt: Date;
  externalId: unknown;
  title: string; // used
  status: string;
  location: JoinLocation;
  description: string; // used
  deadline: unknown; // used
  salary: JoinSalary; // used
  contact: JoinContact;
  attachments: JoinAttachment[];
  employmentType: string;
  minEducation: unknown;
  category: string;
  seniority: unknown;
  yearsOfExperience: unknown;
  language: string;
  contract: string | null; // used
  followers: string[];
}

export interface JoinLocation {
  countryIso: string;
  city: string;
  remote: boolean;
}

export interface JoinSalary {
  from: unknown;
  to: unknown;
  currency: unknown;
  isShownOnJobAd: boolean;
  frequency: string;
}

export interface JoinContact {
  name: string | null;
  email: string | null;
  position: string | null;
}

export interface JoinAttachment {
  type: string;
  constraint: string;
}

export interface JoinApplicationForm {
  cv: File;
  dsgvo: boolean;
  email: string;
  firstName: string;
  lastName: string;
}



