import {Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import {PopperContent} from 'ngx-popper';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {AppNavStatics} from './app-nav-statics';
import {NavData} from './interfaces/nav-data';
import {ShowHideEvent} from './show-hide-event';

@Component({
    selector: 'nt-app-nav',
    templateUrl: './app-nav.component.html',
    styleUrls: ['./app-nav.component.scss']
})
export class AppNavComponent {
    @ViewChild('mobileMenu', {static: true}) mobileMenu: ElementRef;
    private openPopper: PopperContent;

    private unlisten: () => void;

    readonly navData: NavData = AppNavStatics.navData;

    constructor(
        private renderer: Renderer2) {
    }

    myShowFunction(showHideEvent: ShowHideEvent): void {
        if (showHideEvent.popper === this.openPopper) {
            return;
        }

        this.openPopper = showHideEvent.popper;
        this.openPopper.show();

        if (showHideEvent.entryEvent) {
            if (showHideEvent.entryEvent instanceof FocusEvent) {
                const popupList = showHideEvent.popper.elemRef.nativeElement.getElementsByTagName('ul')[0];
                const lastLink = popupList.getElementsByTagName('a')[popupList.getElementsByTagName('a').length - 1];

                this.unlisten = this.renderer.listen(
                    popupList,
                    'focusout',
                    event => {
                      if(event.target === lastLink) {
                        this.myHideFunction();
                      }
                    }
                );

            }
            else if( ((showHideEvent.entryEvent.target as HTMLElement).classList.contains('main-nav-item') ||
                (showHideEvent.entryEvent.target as HTMLElement).classList.contains('nav-link'))) {
                this.unlisten = this.renderer.listen('document', 'touchstart', event => {
                // if (!event.popper?.elemRef.nativeElement.contains(event.target)) {
                //         this.myHideFunction();
                //     }
                });
            }
        }
    }

    myHideFunction(showHideEvent?: ShowHideEvent): void {
        if (showHideEvent) {
            this.disposeToucheListener();
        }

      this.hideActivePopper()
    }

    mobileOpenFunction() {
      this.mobileMenu.nativeElement.classList.add('show');
        this.renderer.addClass(document.documentElement, 'no-scroll');
        disableBodyScroll(this.mobileMenu);
    }

    mobileCloseFunction() {
      this.mobileMenu.nativeElement.classList.remove('show');
        this.renderer.removeClass(document.documentElement, 'no-scroll');
        enableBodyScroll(this.mobileMenu);
    }

    private disposeToucheListener() {
        if (this.unlisten) {
            this.unlisten();
            this.unlisten = undefined;
            return true;
        }
        return false;
    }

    private hideActivePopper(): boolean {
        this.disposeToucheListener();
        if (this.openPopper) {
            this.openPopper.hide();
            this.openPopper = undefined;
            return true;
        }
        return false;
    }

}
