import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {BlackbirdPost, BlackbirdPostResponse} from './blackbird.interface';

@Injectable({
  providedIn: 'root'
})
export class PostResolverService implements Resolve<BlackbirdPostResponse> {

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private transferState: TransferState,
              private $http: HttpClient) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BlackbirdPostResponse> {

    const slug = route.params.slug;
    const COURSE_KEY = makeStateKey<BlackbirdPost>(slug);

    if (this.transferState.hasKey(COURSE_KEY)) {
      const data = this.transferState.get<BlackbirdPostResponse>(COURSE_KEY, null);
      return of(data);
    } else {
      const token = route.data.environment.blackbird.token;
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.set('Accept', 'application/json');
      headers = headers.set('authorization', 'Bearer ' + token);
      return this.$http.get<BlackbirdPostResponse>(this.getUrl(route.data.environment, route, slug), {
        headers: headers
      })
        .pipe(
          map(data => data.data),
          tap(loadedData => {
            this.transferState.set(COURSE_KEY, loadedData);
          }),

          catchError((err) => {
            if (err.status === 404) {
              console.error(`Blackbird posts ${err.url} doesn't exist or is inavtive`);
            }
            console.log('Blackbird error: ', err);
            return of({} as any)
          })
        );
    }
  }

  /**
   * get url of blackbird posts/view endpoint
   *
   * @param env
   * @param route
   * @private
   */
  private getUrl(env, route, slug): string {
    let host = env.browser.jsonHost;
    if (isPlatformServer(this.platformId)) {
      host = env.ssr.jsonHost;
    }
    return host + `/${env.blackbird.posts}/${slug}${route.data.post.queryString}`;
  }

}
