<ng-container *ngIf="!blankSsr">
  <div class="googlemap-cover" *ngIf="useCover; else renderGoogleMap">
    <img *ngIf="coverPath" [src]="coverPath">
    <div class="googlemap-consent-container">
      <p class="consent-message">{{consentMessageText}}</p>
      <button class="consent-button" (click)="openConsentDialog()">{{consentButtonText}}</button>
    </div>
  </div>

  <ng-template #renderGoogleMap>
    <google-map
      width="100%"
      [zoom]="zoom"
      [center]="center"
      [options]="options">
      <map-marker
        [position]="center"
        [title]="title"
      ></map-marker>
    </google-map>
  </ng-template>
</ng-container>
