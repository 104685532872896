import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PopperContent } from 'ngx-popper';
import { DesktopNavItem } from '../interfaces/desktop-nav-item';
import { ShowHideEvent } from '../show-hide-event';

@Component ( {
  selector   : 'nt-desktop-nav-item',
  templateUrl: './desktop-nav-item.component.html',
  styleUrls  : ['./desktop-nav-item.component.scss']
} )
export class DesktopNavItemComponent {

  @Input () navDataItem: DesktopNavItem;
  @Input () classToUse: string;

  @Output () customMouseEnter: EventEmitter<ShowHideEvent> = new EventEmitter ();
  @Output () customMouseLeave: EventEmitter<ShowHideEvent> = new EventEmitter ();

  show( popperScope: PopperContent, $event: MouseEvent | FocusEvent): void {
    this.customMouseEnter.emit ( new ShowHideEvent ( popperScope, $event ) );
  }

  hide( popperScope: PopperContent ): void {
    this.customMouseLeave.emit ( new ShowHideEvent ( popperScope ) );
  }

}
