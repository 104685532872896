<ng-container *ngIf="navDataItem.popperData else directLink">

  <!--  Todo:?     (focusin)="show(popperScope)"-->
  <div (click)="show(popperScope, $event)"
       (focusin)="show(popperScope, $event)"
       (mouseenter)="show(popperScope, $event)"
       (mouseleave)="hide(popperScope)"
       tabindex="0"
       class="main-nav-item">
    <popper-content #popperScope>
      <ul>
        <li class="popper-nav-li"
            *ngFor="let popperData of navDataItem.popperData">
          <nt-nav-link-item [linkData]="popperData"
                            [classToUse]="popperData.className"></nt-nav-link-item>

        </li>

      </ul>
    </popper-content>
    <a
      [popperHideOnMouseLeave]="true"
      [popperApplyClass]="'nav-dropdown'"
      [popperHideOnClickOutside]="true"
      [popperHideOnScroll]="true"
      [popperPlacement]="'bottom-start'"
      [popper]="popperScope"
      [routerLink]='navDataItem.routerLink'
      routerLinkActive="active"
       class="nav-link">{{navDataItem.title}}</a>
  </div>

</ng-container>

<ng-template #directLink tabindex="0">
  <div class="main-nav-item">
    <a class="single-link"
       [routerLink]="navDataItem.routerLink"
       routerLinkActive="active"
    >{{navDataItem.title}}</a>
  </div>
</ng-template>
