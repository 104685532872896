import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientEnvironment, ENVIRONMENT } from '@ed-clients/shared';

import { JoinApplicationForm, JoinJob } from './jobs-join.interface';

@Injectable( {
  providedIn: 'root'
} )
export class JobsJoinService {

  constructor(
    private http: HttpClient,
    @Inject( ENVIRONMENT ) private environment: ClientEnvironment
  ) {
  }

  getJobs() {
    return this.http.get<JoinJob[]>( this.environment.join.host + '/getJoinJobs' );
  }

  async submitApplicationForm(applicatonForm: JoinApplicationForm, otherFiles: File[], id: number) {
    const documents: {type: string; name: string; data: string}[] = [];

    const cvEncodedString = await this.toBase64(applicatonForm.cv);
    documents.push({
      type: 'CV',
      name: applicatonForm.cv.name,
      data: cvEncodedString as string
    })

    const collectPromises = otherFiles.map( async file => {
      return {
        type: 'OTHER',
        name: file.name,
        data: await this.toBase64(file) as string
      }
    })
    const resolvedMappedOtherFiles = await Promise.all(collectPromises)

    const body = {
      candidate: {
        email: applicatonForm.email,
        firstName: applicatonForm.firstName,
        lastName: applicatonForm.lastName
      },
      documents: documents.concat(resolvedMappedOtherFiles),
      jobId: id
    }

    // console.log('hey', body);
    // return EMPTY;
    return this.http.post( this.environment.join.host + '/sendApplication', body);
  }

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(',')[1]);
    reader.onerror = error => reject(error);
  });
}
