import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { JobsJoinEffectsService } from './jobs-join.effects.service';
import { JoinContractPipe, JoinSlugPipe } from './jobs-join.pipe';
import { jobsJoinReducer } from './jobs-join.reducer';
import { joinJobsFeatureKey } from './jobs-join.selectors';

@NgModule({
  declarations: [JoinContractPipe, JoinSlugPipe],
  imports: [
    CommonModule,
    StoreModule.forFeature ( joinJobsFeatureKey, jobsJoinReducer ),
    EffectsModule.forFeature ( [JobsJoinEffectsService] ),
  ],
  exports: [JoinContractPipe, JoinSlugPipe]
})
export class JobsJoinStoreModule { }
