import {Component, ElementRef, Input} from '@angular/core';

import {AbstractLazyPictureComponent} from '../abstract-lazy-picture.component';
import {IntersectionObserverService} from '../intersection-observer.service';

@Component({
  selector: 'ed-clients-lazy-picture',
  templateUrl: './lazy-picture.component.html',
  styleUrls: ['./lazy-picture.component.scss']
})
export class LazyPictureComponent extends AbstractLazyPictureComponent {

  _imgUri: string;
  @Input() set imgUri(value: string) {
    this._imgUri = value;
    super.checkReInit();
  }
  @Input() alt: string;
  @Input() title: string;

  imgSrc: string;

  constructor(
    protected intersectionObserver: IntersectionObserverService,
    protected el: ElementRef,
  ) {
    super(intersectionObserver, el);
  }

  showImg() {
    this.imgSrc = this._imgUri;
  }
}
