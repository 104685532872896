import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { selectVideos, VIDEOS_LOAD } from '@ed-clients/common-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BlackbirdPost } from './blackbird.interface';
import { resolvePosts } from './resolve-posts.fnc';

@Injectable ( {
  providedIn: 'root'
} )
export class VideosResolver implements Resolve<BlackbirdPost[]> {

  constructor(
    protected store: Store
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BlackbirdPost[]> {
    return resolvePosts(this.store, selectVideos, VIDEOS_LOAD, route, state);
  }
}
