import { ERROR_RESET, ErrorCodeDetails } from '@nettrek-angular-libs/common-store';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { DialogService } from '../light-weight-dialog/dialog.service';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

export class AppErrorHandler {

  constructor (
    private readonly store: Store,
    public dialog: DialogService,
  ) {}

  show ( error: ErrorCodeDetails ) {
    const ref = this.dialog.open(ErrorDialogComponent, {data: error})

    ref.afterClosed
      .pipe(take(1))
      .subscribe ( () => {
        this.close()
      } );
  }

  close () {
     this.store.dispatch( ERROR_RESET() );
  }
}
