import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FaqEffectsService } from './faq.effects.service';
import { faqReducer } from './faq.reducer';
import { faqFeatureKey } from './faq.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature ( faqFeatureKey, faqReducer ),
    EffectsModule.forFeature ( [FaqEffectsService] ),
  ]
})
export class FaqStoreModule { }
