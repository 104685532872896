import { Schema, Seo, SocialMedia } from '@ed-clients/shared';

export interface Job {
  id: string;
  name?: string;
  description?: string;
  cover?: {
    media: {
      url: string;
    }
  }

  customFields: {
    ed_cf_slug: string;
    ed_cf_jobs_job_type?: string;
    ed_cf_jobs_profile?: string;
    ed_cf_jobs_benefits?: string;
    ed_cf_seo_social_media: string; // needed to be parsed
  }

  // mapped from custom fields
  seo: Seo;
  schemas: Schema[];
  socialMedia: SocialMedia;
}
