import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'translateContract' })
export class JoinContractPipe implements PipeTransform { // eslint-disable-line
  transform(contract: string | null): string {
    if(contract && contract.toLowerCase().includes('part')) {
      return 'Teilzeit'
    } else {
      return 'Vollzeit'
    }
  }
}

@Pipe({ name: 'convertToJoinSlug' })
export class JoinSlugPipe implements PipeTransform { // eslint-disable-line
  transform(jobTitle: string, id: number): string {
    const jobTitleUri = jobTitle.toLowerCase().replace(/[^\w ]/g, ' ').trim().replace(/ +/g, '-');

    return id.toString() + '-' + jobTitleUri;
  }
}
