import {Component, ElementRef, Input} from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {SwMedia} from '@ed-clients/shopware';

import {AbstractLazyPictureComponent} from '../abstract-lazy-picture.component';
import {IntersectionObserverService} from '../intersection-observer.service';

@Component({
  selector: 'ed-clients-lazy-sw-picture',
  templateUrl: './lazy-sw-picture.component.html',
  styleUrls: ['./lazy-sw-picture.component.scss']
})
export class LazySwPictureComponent extends AbstractLazyPictureComponent {

  media: SwMedia;
  @Input() set swMedia(value: SwMedia) {
    this.media = value;
    if(value) {
      this.imgAlt = value.alt;
    }
    super.checkReInit();
  }

  private _thumbSize: 400 | 800 | 1920;
  @Input() set thumbSize(value: '400' | '800' | '1920') {
    this._thumbSize = parseInt(value, 10) as 400 | 800 | 1920;
  }

  imgSrc: string;
  imgAlt: string;

  constructor(
    protected intersectionObserver: IntersectionObserverService,
    protected el: ElementRef,
  ) {
    super(intersectionObserver, el);
  }

  showImg() {
    if(this.media && this.media.url) {
      if(this._thumbSize) {
        const thumbObj = this.media.thumbnails.find( thumb => thumb.width === this._thumbSize);
        if(thumbObj && thumbObj.url) {
          this.imgSrc = thumbObj.url;
          return;
        }
      }
      this.imgSrc = this.media.url;
      return;
    }
    this.imgSrc = null;
  }
}
