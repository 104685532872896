<div (click)="mobileCloseFunction()" class="mobile-section-header">
  <a class="section-heading" *ngIf="mobileMenuData.title === 'Leistungen'; else notLeistungen" routerLink="/leistungen">{{mobileMenuData.title}}</a>
  <ng-template #notLeistungen>
    <span class="section-heading">{{mobileMenuData.title}}</span>
  </ng-template>
</div>
<ul>
    <li class="popper-nav-li" *ngFor="let mobileNavItem of mobileMenuData.navs">
        <ng-container *ngIf="mobileNavItem">
            <nt-nav-link-item [linkData]="mobileNavItem"
                              [classToUse]="mobileNavItem.className"
                              (click)="mobileCloseFunction()"></nt-nav-link-item>
        </ng-container>
    </li>
</ul>
