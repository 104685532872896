import { Component } from '@angular/core';
import { DialogConfig } from '../../light-weight-dialog/dialog-config';
import { DialogRef } from '../../light-weight-dialog/dialog-ref';
import { ConsentDialogData } from './consent-dialog-data.interface';

@Component({
  selector: 'ed-clients-google-map-consent-dialog',
  templateUrl: './google-map-consent-dialog.component.html',
  styleUrls: ['./google-map-consent-dialog.component.scss']
})
export class GoogleMapConsentDialogComponent {

  constructor( private dialogRef: DialogRef, public config: DialogConfig<ConsentDialogData> ) { }

  agreeSavingCookie(agree: boolean) {
    this.dialogRef.close(agree)
  }
}
