import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {BlackbirdPost, BlackbirdPostsResponse} from './blackbird.interface';
// import { MettaInfoService } from './metaaInfoService } from './meta-info.service';

@Injectable({
  providedIn: 'root'
})
export class PostsResolverService implements Resolve<BlackbirdPost[]> {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private transferState: TransferState,
    private $http: HttpClient,
    // private metaInfo: MetaInfoService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BlackbirdPost[]> {
    const COURSE_KEY = makeStateKey<BlackbirdPost[]>('posts');

    if (this.transferState.hasKey(COURSE_KEY)) {
      const data = this.transferState.get<BlackbirdPost[]>(COURSE_KEY, []);
      return of(data).pipe(
        tap(posts => { // setup seo only on news details page
          if(route.params.slug) {
            const currentPost = posts.filter( post => post.slug === route.params.slug )[0];
/*        TODO
            this.metaInfo.updateMetaInfos(
              { seo: currentPost.seo, socialMedia: currentPost.socialMedia, schema: currentPost.schema, schemas: currentPost.schemas }
            );
            */
          }
        } )
      );
    } else {
      const token = route.data.environment.blackbird.token;
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.set('Accept', 'application/json');
      headers = headers.set('authorization', 'Bearer ' + token);
      return this.$http.get<BlackbirdPostsResponse>(this.getUrl(route.data.environment, route), {
        headers: headers
      })
        .pipe(
          map(data => data.data),
          tap(loadedData => {
            this.transferState.set(COURSE_KEY, loadedData);
            if(route.params.slug) {
              const currentPost = loadedData.filter( post => post.slug === route.params.slug )[0];
            /* TODO
              this.metaInfo.updateMetaInfos(
                { seo: currentPost.seo, socialMedia: currentPost.socialMedia, schema: currentPost.schema, schemas: currentPost.schemas }
              );
              */
            }
          }),
          catchError((err) => {
            if (err.status === 404) {
              console.error(`Blackbird post ${err.url} doesn't exist or is inavtive`);
            }
            console.log('Blackbird error: ', err);
            return of({} as any)
          })
        );
    }
  }

  /**
   * get url of blackbird posts/index endpoint
   *
   * @param env
   * @param route
   * @private
   */
  private getUrl(env, route): string {
    let host = env.browser.jsonHost;
    if (isPlatformServer(this.platformId)) {
      host = env.ssr.jsonHost;
    }
    return host + `/${env.blackbird.posts}${route.data.posts.queryString}`;
  }
}
