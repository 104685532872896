import { Inject, Injectable, Optional } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { ERROR_IGNORE_URLS } from './error.token';

@Injectable ()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor ( private $error: ErrorService,
                @Optional () @Inject ( ERROR_IGNORE_URLS ) private ignoreURL: string [] ) {
    if ( !ignoreURL ) {
      this.ignoreURL = [];
    }
  }

  /**
   * Sollte es zu einem HttpErrorResponse kommen wird geprüft ob es ein unauthorisierter Zugriff war und entsprechend
   * ein Feedback gegeben. Anderenfalls wird geschaut, ob die URL sein eigenen Error Handlindg hat oder ein globale error-store
   * wird geworfen
   * @param request
   * @param next
   */
  intercept ( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    return next.handle ( request )
               .pipe (
                 // retry ( 1 ), muss vorerst deaktiviert werden da sonst der progress zähle verrück spielt
                 catchError ( ( error: HttpErrorResponse ) => {
                   switch ( error.status ){
                     case 401:
                       break;

                     default:
                       if ( this.urlHasNotOwnErrorHandling ( error.url ) ) {
                         this.$error.setFatalError ( this.$error.getServerMessage ( error ) );
                       }
                       break;

                   }
                   return throwError ( error );

                 } )
               );
  }

  /**
   * Bestimmte URLs werden übergeordnet abgefangen diese dürfen nicht im globalen Error Handling berücksichtigt werden!
   * @param url
   */
  urlHasOwnErrorHandling ( url: string ): boolean {
    return this.ignoreURL
               .map ( val => val.replace ( /^./, '' ) )
               .filter ( v => url.indexOf ( v ) !== - 1 )
      .length > 0;
  }

  urlHasNotOwnErrorHandling ( url ): boolean {
    return !this.urlHasOwnErrorHandling ( url );
  }
}


