import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Reference, REFERENCES_LOAD, selectProjectReferences } from '@ed-clients/common-store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

// prerequisites:
// params key :jobSlug is used
// use /seite-nicht-gefunden

@Injectable ( {
    providedIn: 'root',
} )
export class ReferenceDetailsResolver implements Resolve<Reference> {
    loaded = false; // this boolean prevents the store from dispatching again and again when there are no jobs in shopware

    constructor( private store: Store, private router: Router ) {
    }

    resolve( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<Reference> {

        return this.store.select ( selectProjectReferences )
                   .pipe (
                       tap ( references => {
                           if ( references.length === 0 && !this.loaded ) {
                               this.store.dispatch ( REFERENCES_LOAD ( {} ) );
                           }
                       } ),
                       map ( references => {
                           return references.filter ( reference => reference.customFields.ed_cf_slug === route.params.referenceSlug )[ 0 ];
                       } ),
                       filter ( reference => {
                           if ( !reference && this.loaded ) {
                               this.router.navigateByUrl ( '/seite-nicht-gefunden' );
                           }
                           this.loaded = true;
                           return !!reference;
                       } ),
                       take ( 1 ),
                   );
    }
}