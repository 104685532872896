import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { DialogConfig } from '../../light-weight-dialog/dialog-config';
import { DialogRef } from '../../light-weight-dialog/dialog-ref';
import { interval } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { first } from 'rxjs/operators';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { INTERNAL_LIB_ERROR_FEEDBACK_MAIL } from '../../common-injection-token';
import { ErrorCodeDetails } from '../error-codes.interface';

@Component ( {
  selector   : 'ed-clients-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls  : ['./error-dialog.component.scss']
} )
export class ErrorDialogComponent extends OnDestroyMixin implements AfterViewInit {
  num = 0;
  mailto: string | undefined;
  @ViewChild ( 'desc' )
  private desc: ElementRef<HTMLDivElement>;

  constructor (
    private dialogRef: DialogRef,
    public config: DialogConfig<ErrorCodeDetails>,
    @Inject ( DOCUMENT ) private doc: Document,
    @Inject ( INTERNAL_LIB_ERROR_FEEDBACK_MAIL ) private mail: string,
  ) {
    super ();
  }

  close(){
    this.dialogRef.close();
  }

  ngAfterViewInit (): void {
    // eslint-disable-next-line max-len
    const m = `mailto:${this.mail}?subject=${encodeURIComponent ( 'Problemmeldung: ' + this.config.data.title )}&body=${encodeURIComponent ( 'Vielen Dank, dass Sie uns diesen Fehler übermitteln.\n\n\t' + this.desc.nativeElement.innerText + '\n\n Gerne können Sie hier noch weitere Details hinzufügen!' + '\n\nURL:' )}${this.doc.location.href}`;
    // wert wird sonst im Lifecycle 2 mal gesetzte
    interval ( 10 )
      .pipe ( first (), untilComponentDestroyed ( this ) )
      .subscribe (
        () => this.mailto = m
      );
  }
}
