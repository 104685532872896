import {PopperContent} from 'ngx-popper';

export class ShowHideEvent {
    popper: PopperContent;
    entryEvent?: MouseEvent | FocusEvent;

// todo: keyboard focus event?
    constructor(popper: PopperContent, mouseEvent?: MouseEvent | FocusEvent) {
        this.popper = popper;
        this.entryEvent = mouseEvent;
    }
}
