import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {
  JOBS_JOIN_LOAD,
  JoinJob,
  selectJoinJobs,
} from '@ed-clients/common-store';

@Injectable( {
  providedIn: 'root'
} )
export class JoinJobsResolver implements Resolve<JoinJob[]> {
  loaded = false; // this boolean prevents the store from dispatching again and again
  count  = 0; // this count prevents the store from dispatching again and again

  constructor(
    protected store: Store
  ) {
  }

  // return empty array if no news available
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<JoinJob[]> {
    return this.store.select ( selectJoinJobs )
      .pipe (
        tap ( jobs => {
          this.count ++; // count = 2 means loaded
          if ( jobs.length === 0 && !this.loaded && this.count < 2 ) {
            this.store.dispatch ( JOBS_JOIN_LOAD() );
          } else {
            this.loaded = true;
          }
        } ),
        filter ( () => this.loaded ),
        take ( 1 ),
      );
  }
}
