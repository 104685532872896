// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {SwProductMedia} from '@ed-clients/shopware';

import { Schema, Seo, SocialMedia } from '../seo/meta.interface';

export interface SwStoreResponse<T> {
  aggregations: string[]
  apiAlias: string;
  elements: T[];
  limit: number;
  page: number;
  total: number;
}

export interface Job {
  id: string;
  name: string;
  description: string;
  cover: SwProductMedia;
  keywords: string;
  metaDescription: string;
  metaTitle: string;
  customFields?: {
    ed_cf_jobs_job_type: string;
    ed_cf_jobs_profile: string;
    ed_cf_jobs_benefits: string;
  }
}

export interface Reference {
  id: string;
  name: string;
  description: string;
  cover: SwProductMedia;
  keywords: string;
  metaDescription: string;
  metaTitle: string;
  customFields?: {
    ed_cf_references_action: string;
    ed_cf_references_client: string;
    ed_cf_references_location: string;
    ed_cf_references_projectStart: string;
    ed_cf_references_projectEnd: string;
    ed_cf_references_houses: string;
    ed_cf_references_area: string;
    ed_cf_references_phase: string;
    ed_cf_references_projectState: string;
  }
}

export interface RealEstate {
  id: string;
  name: string;
  description?: string;
  currentPrice: string;
  markAsTopseller?: boolean;
  images: SwProductMedia[];
  cover: SwProductMedia;
  customFields?: {
    ed_cf_seo_social_media: string; // needed to be parsed
    ed_cf_real_estate_immobilienScoutId: string;
    ed_cf_real_estate_street: string;
    ed_cf_real_estate_title_houseNumber: string;
    ed_cf_real_estate_postcode: string;
    ed_cf_real_estate_city: string;
    ed_cf_real_estate_stadtteil: string;
    ed_cf_real_estate_country: string;
    ed_cf_real_estate_latitude: number;
    ed_cf_real_estate_longitude: number;
    ed_cf_real_estate_status: string;
    ed_cf_real_estate_price_currency: string;
    ed_cf_real_estate_price_value: number;
    ed_cf_real_estate_livingSpaceInSquareMeters: number;
    ed_cf_real_estate_number_of_rooms: number;
    ed_cf_real_estate_type: string;
    ed_cf_real_estate_creationDate: Date;
    ed_cf_real_estate_lastModificationDate: Date;
    ed_cf_real_estate_floorplan: string;
    ed_cf_real_estate_apartmentType: string;
    ed_cf_real_estate_lift: string;
    ed_cf_real_estate_assistedLiving: string;
    ed_cf_real_estate_cellar: string;
    ed_cf_real_estate_handicappedAccessible: string;
    ed_cf_real_estate_courtage_hasCourtage: string;
    ed_cf_real_estate_courtage_courtage: string;
    ed_cf_real_estate_courtage_courtageNotes: string;
    ed_cf_real_estate_buildingEnergyRatingType: string;
    ed_cf_real_estate_energyConsumptionContainsWarmWater: string;
    ed_cf_real_estate_guestToilet: string;
    ed_cf_real_estate_baseRent: number;
    ed_cf_real_estate_heatingCostsInServiceCharge: string;
    ed_cf_real_estate_petsAllowed: string;
    ed_cf_real_estate_energyPerformanceCertificate: string;
    ed_cf_real_estate_builtInKitchen: string;
    ed_cf_real_estate_balcony: string;
    ed_cf_real_estate_certificateOfEligibilityNeeded: string;
    ed_cf_real_estate_garden: string;
    ed_cf_real_estate_numberOfParkingSpaces: number;
    ed_cf_real_estate_condition: string;
    ed_cf_real_estate_constructionYear: number;
    ed_cf_real_estate_freeFrom: string;
    ed_cf_real_estate_energySourcesEnev2014: string;
    ed_cf_real_estate_numberOfFloors: number;
    ed_cf_real_estate_usableFloorSpace: number;
    ed_cf_real_estate_numberOfBedRooms: number;
    ed_cf_real_estate_numberOfBathRooms: number;
    ed_cf_real_estate_deposit: string;
    ed_cf_real_estate_parkingSpaceType: string;
    ed_cf_real_estate_parkingSpacePrice: number;
    ed_cf_real_estate_lastRefurbishment: number;
    ed_cf_real_estate_interiorQuality: string;
    ed_cf_real_estate_heatingType: string;
    ed_cf_real_estate_rented: string;
    ed_cf_real_estate_rentalIncome: number;
    ed_cf_real_estate_plotArea: number;
    ed_cf_real_estate_furnishingNote: string;
    ed_cf_real_estate_locationNote: string;
    ed_cf_real_estate_otherNote: string
    ed_cf_real_estate_externalId: string;
    ed_cf_real_estate_totalRent: number;
    ed_cf_real_estate_serviceCharge: number;
    ed_cf_real_estate_heatingCosts: number;
    ed_cf_real_estate_building_type: string;
    ed_cf_real_estate_contactDetailsEmail: string;
    ed_cf_real_estate_contactDetailsFirstName: string;
    ed_cf_real_estate_contactDetailsLastName: string;
    ed_cf_real_estate_contactDetailsFaxNumber: string;
    ed_cf_real_estate_contactDetailsPhoneNumber: string;
    ed_cf_real_estate_contactDetailsCellPhoneNumber: string;
  }

  // mapped from custom fields
  seo: Seo;
  schemas: Schema[];
  socialMedia: SocialMedia;
}
