import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { fromEvent, race, timer } from 'rxjs';
import { take } from 'rxjs/operators';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

console.log('nettrek: ' + environment.version);

if (environment.production) {
  enableProdMode();

  const console = (window.console || {}) as Console;
  for (const consoleKey in console) {
    if (Object.prototype.hasOwnProperty.call(console, consoleKey) &&
      !!consoleKey && consoleKey !== 'error') {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      console[consoleKey] = () => {};
    }
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// https://constantsolutions.dk/2020/06/delay-loading-of-google-analytics-google-tag-manager-script-for-better-pagespeed-score-and-initial-load/
// delay loading of GTM for better performance results of light house
document.addEventListener('DOMContentLoaded', () => {
  race(
    fromEvent(document, 'touchstart'),
    fromEvent(document, 'scroll'),
    fromEvent(document, 'mousemove'),
    fromEvent(window, 'resize'),
    fromEvent(window, 'beforeunload'),
    timer(5000),
  ).pipe(take(1)).subscribe(() => {
    initGTM();
    // initGlobalStyles();
  });
});

function initGTM() {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.onload = () => {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.uniqueEventId': 0 });
  }; // this part ensures PageViews is always tracked
  script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-5FZ2LX7';
  document.head.appendChild(script);
}

// https://javascript.plainenglish.io/how-to-exclude-stylesheets-from-the-bundle-and-lazy-load-them-in-angular-ce159903f6d2
// function initGlobalStyles() {
//   const link = document.createElement('link');
//   link.href = 'test.css';
//   link.rel = 'stylesheet'
//   document.head.appendChild(link);
// }
