import { Injectable } from '@angular/core';
import { convertIntoGermanTelOrFaxValue } from '@ed-clients/shared';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map } from 'rxjs/operators';
import { CONFIG_BLACKBIRD_LOAD, CONFIG_BLACKBIRD_SET } from './blackbird-config.actions';
import { BlackbirdConfigService } from './blackbird-config.service';

@Injectable()
export class BlackbirdConfigEffectsService {

  constructor(
    private actions: Actions,
    private blackbirdConfigService: BlackbirdConfigService
  ) {
  }

  loadConfig = createEffect( () => this.actions.pipe(
    ofType( CONFIG_BLACKBIRD_LOAD ),
    mergeMap( () => this.blackbirdConfigService.getBlackbirdConfig().pipe(
      map( ( res ) => {
        res.data.callNumValue = convertIntoGermanTelOrFaxValue(res.data.callNumText);
        res.data.faxNumValue = convertIntoGermanTelOrFaxValue(res.data.faxNumText);


        return CONFIG_BLACKBIRD_SET({ configData: res.data })
      } )
    ) )
  ), { dispatch: true } );
}
