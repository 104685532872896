import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ERROR_FEEDBACK_MAIL, SHOW_ERROR_HANDLER } from '@nettrek-angular-libs/common-store';
import { Store } from '@ngrx/store';
import { DialogService } from '../light-weight-dialog/dialog.service';
import { LightWeightDialogModule } from '../light-weight-dialog/light-weight-dialog.module';
import { AppErrorHandler } from './app-error-handler';

import { INTERNAL_LIB_ERROR_FEEDBACK_MAIL } from '../common-injection-token';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

@NgModule ( {
  declarations: [ErrorDialogComponent],
  imports: [
    CommonModule,
    LightWeightDialogModule
  ],
  providers   : [
    { provide: INTERNAL_LIB_ERROR_FEEDBACK_MAIL, useExisting: ERROR_FEEDBACK_MAIL },
    {
      provide: SHOW_ERROR_HANDLER, useClass: AppErrorHandler, deps: [
        Store,
        DialogService
      ]
    }
  ]
} )
export class CustomDialogErrorHandlerModule {
}
