import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent, HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { COM_PROGRESS_DECREMENT, COM_PROGRESS_INCREMENT } from './communication.actions';


@Injectable ()
export class AppProgressInterceptorService implements HttpInterceptor {
  lastResponse: HttpEvent<any>;
  error: HttpErrorResponse;

  constructor ( private store: Store ) {
  }

  intercept ( req: HttpRequest<unknown>, next: HttpHandler ): Observable<HttpEvent<unknown>> {
    this.increment ( req.url );
    return next.handle ( req )
               .pipe (
                 tap (
                   res => {
                     this.lastResponse = res;
                     if ( res instanceof HttpResponse ) { // success
                       this.decrement ( req.url );
                     }
                   },
                   ( err ) => {
                     this.error = err;
                     this.decrement ( req.url );
                   }
                 ) ,
                 finalize(() => { // this handle those cancelled calls
                   if (this.lastResponse.type === HttpEventType.Sent && !this.error) {
                     // last response type was 0, and we haven't received an error
                     this.decrement ( req.url );
                   }
                 })
               )
      ;
  }

  private increment ( url: string ) {
    this.store.dispatch ( COM_PROGRESS_INCREMENT ( { url } ) );
  }

  private decrement ( url: string ) {
    timer ( 300 )
      .subscribe (
        () => this.store.dispatch ( COM_PROGRESS_DECREMENT ( { url } ) )
      );
  }
}
