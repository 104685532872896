import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import { LazyPictureModule } from '@ed-clients/shared';
import {NgxPopperModule} from 'ngx-popper';
import {AppNavComponent} from './app-nav.component';
import {DesktopNavItemComponent} from './desktop-nav-item/desktop-nav-item.component';
import {MobileSectionItemComponent} from './mobile-section-item/mobile-section-item.component';
import {NavLinkItemComponent} from './nav-link-item/nav-link-item.component';

@NgModule({
  declarations: [
    AppNavComponent,
    DesktopNavItemComponent,
    MobileSectionItemComponent,
    NavLinkItemComponent
  ],
  imports: [
    CommonModule,
    NgxPopperModule.forRoot({}),
    RouterModule,
    LazyPictureModule
  ],
  exports: [
    AppNavComponent,
    DesktopNavItemComponent,
  ]
})
export class AppNavModule {
}
