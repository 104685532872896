import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { CookieService } from 'ngx-cookie-service';
import { take } from 'rxjs/operators';
import { DialogService } from '../light-weight-dialog/dialog.service';
import { GoogleMapConsentDialogComponent } from './google-map-consent-dialog/google-map-consent-dialog.component';

@Component({
  selector: 'ed-clients-nettrek-google-map',
  templateUrl: './nettrek-google-map.component.html',
  styleUrls: ['./nettrek-google-map.component.scss']
})
export class NettrekGoogleMapComponent extends OnDestroyMixin implements OnInit {
  @Input() coverPath?: string;
  @Input() googleApiKey = 'AIzaSyAGO22Cr41iz_9_VobkmxxfaSgR8UKWXWg';
  @Input() zoom = 17;
  @Input() center!: google.maps.LatLngLiteral;
  @Input() options: google.maps.MapOptions = {
    scrollwheel: false,
  };
  @Input() title?: string;
  @Input() consentButtonText = 'Karte einblenden';
  @Input() consentMessageText = 'Wir benötigen Ihre Zustimmung, um Ihnen an dieser Stelle eine Karte von Google Maps anzeigen zu dürfen. Es wird eine Verbindung zu den Servern von Google aufgebaut.'
  @Input() consentDialogButtonText = 'Ok';
  @Input() consentDialogMessageText = 'Es wird eine Verbindung zu den Servern von Google aufgebaut, um Ihnen die Karte anzeigen zu können.';

  useCover = true;
  blankSsr = false;

  constructor(@Inject ( PLATFORM_ID ) private platformId, private dialog: DialogService, private cookieService: CookieService ) {
    super()
  }

  ngOnInit(): void {
    if(isPlatformBrowser ( this.platformId )) {
      if(this.cookieService.get('google-map-consent') === 'true') {
        if(document.getElementById('mapsScript')) {
          this.useCover = false;
        } else {
          this.loadGoogleMapScript(false)
        }
      }
    } else {
      this.blankSsr = true;
    }
  }

  openConsentDialog() {
    const consentDialog = this.dialog.open(GoogleMapConsentDialogComponent, { data: {
        consentDialogButtonText: this.consentDialogButtonText,
        consentDialogMessageText: this.consentDialogMessageText
      } })

    consentDialog.afterClosed.pipe(
      take(1),
      untilComponentDestroyed(this)
    ).subscribe( useCookie => {
      if(useCookie) {
        this.loadGoogleMapScript(true)
      }
    })
  }

  loadGoogleMapScript(setCookie: boolean) {
    const script = document.createElement('script');
    script.id = 'mapsScript';
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}`;
    script.onload = () => {
      this.useCover = false;
      if(setCookie) {
        this.cookieService.set('google-map-consent', 'true')
      }
    }
    document.body.appendChild( script );
  }
}
