import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BlackbirdPostResponse,
  BlackbirdPostsResponse,
  ClientEnvironment,
  ENVIRONMENT
} from '@ed-clients/shared';

@Injectable ( {
  providedIn: 'root'
} )
export class ProjectsService {

  constructor (
    private http: HttpClient,
    @Inject ( ENVIRONMENT ) private environment: ClientEnvironment,
    @Inject(PLATFORM_ID) private platformId,
  ) {
  }

  getUrl( queryString: string ): string {
    let host = this.environment.browser.jsonHost;
    if (isPlatformServer(this.platformId)) {
      host = this.environment.ssr.jsonHost;
    }
    return host + `${this.environment.blackbird.posts}${queryString}`;
  }

  getProjects( action: { page?: number; limit?: number; query? } ) {
    let paginationQueryParams = '';

    if( action.query ) {
      paginationQueryParams = action.query;
    }

    if( action.page && action.limit ) {
      paginationQueryParams = paginationQueryParams + `&page=${action.page}&limit=${action.limit}`;
    } else {
      paginationQueryParams = paginationQueryParams + '&limit=100';
    }

    const queryString = `?type=${this.environment.blackbird.projectSlug}-references&images=object${paginationQueryParams}`;

    return this.http.get<BlackbirdPostsResponse>(this.getUrl(queryString))
  }

  getSingleProject( action: { query?: string; slug: string } ) {
    let paginationQueryParams = '';

    if( action.query ) {
      paginationQueryParams = action.query;
    }

    const postSlugAndQuery = `/${action.slug}?images=object${paginationQueryParams}`;

    return this.http.get<BlackbirdPostResponse>(this.getUrl(postSlugAndQuery))
  }
}
