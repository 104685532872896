// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SwMedia, SwProductMedia } from '@ed-clients/shopware';

export interface Reference {
    id: string;
    page?: number; // page where the reference belongs to, undefined if pagination is not needed
    name: string;
    description: string;
    cover: SwProductMedia;
    calculatedPrice?: {
        totalPrice: number;
    };
    media: SwProductMedia[];
    categories: {
        id: string;
        name: string;
    }[];
    customFields: {
        ed_cf_slug?: string;
        ed_cf_references_action?: string;
        ed_cf_references_area?: string;
        ed_cf_references_client?: string;
        ed_cf_references_houses?: string;
        ed_cf_references_phase?: string;
        ed_cf_references_projectState?: string;
        ed_cf_references_location?: string;
        ed_cf_references_projectStart?: string;
        ed_cf_references_projectEnd?: string;
        ed_cf_references_subTitle?: string;
        ed_cf_references_value?: string
    };
    markAsTopseller: boolean;
}

export interface ReferencesCategory {
    id: string;
    name: string;
    description?: string;
    media?: SwMedia;
}
