<div class="consent-dialog-container">
  <p>{{config.data.consentDialogMessageText}}</p>

  <div class="consent-actions">
    <button (click)="agreeSavingCookie(false)">Abrechen</button>
    <button (click)="agreeSavingCookie(true)">{{config.data.consentDialogButtonText}}</button>
  </div>
</div>



