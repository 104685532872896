import { FormControl, ValidatorFn } from '@angular/forms';

export interface DynamicFormItem {
  // field title
  title?: string;
  // feldname
  key: string;
  // falls password ... erzwingd isPassword true
  type: 'text' | 'textarea' | 'mail' | 'tel' | 'password' | 'checkbox' | 'number' | 'radio' | 'select' | 'date';
  // erzwingt type=password wenn auf true
  isPassword?: boolean;
  // überschrift im Formular
  label?: string;
  placeholder?: string;
  hint?: string;
  error?: string;
  // setzt required sternchen aber nciht den Validator
  required?: boolean;
  // wird automatisch generiert
  formControl?: FormControl,
  validators?: ValidatorFn[],
  // startwert
  value?: any;
  icon?: string;
  readonly?: boolean;
  radioButtons?: { text:string; value: any }[] | string[];
  selectOptions?: string[];
  minDate?: Date;
  maxDate?: Date;
  maxLength?: number;
}

export interface CommonForm {
  items: DynamicFormItem[];
  resetLabel: string;
  sendLabel: string;
}
