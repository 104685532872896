import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { JOBS_JOIN_LOAD, JoinJob, selectJoinJobs } from '@ed-clients/common-store';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

// prerequisites:
// params key :joinJobSlug is used
// use /seite-nicht-gefunden

@Injectable({
  providedIn: 'root'
})
export class JoinJobResolver implements Resolve<JoinJob> {
  loaded = false; // this boolean prevents the store from dispatching again and again when there are no jobs in shopware

  constructor(private store: Store, private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<JoinJob> {
    return this.store.select( selectJoinJobs ).pipe(
      tap( jobs => {
        if( jobs.length === 0 && !this.loaded ) {
          this.store.dispatch( JOBS_JOIN_LOAD() );
        }
      } ),
      map( jobs => {
        return jobs.filter(job => job.id.toString() === route.params.joinJobSlug.split('-')[0])[0]
      } ),
      filter( job => {
        if( !job && this.loaded ) {
          this.router.navigateByUrl('/seite-nicht-gefunden');
        }
        this.loaded = true;
        return !!job;
      } ),
      take(1)
    )
  }
}
