import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorCodeDetails } from '../error-codes';
import { Store } from '@ngrx/store';
import { interval, Observable } from 'rxjs';
import { selectErrorIsFatalState } from '../_store/error.selectors';
import { DOCUMENT } from '@angular/common';
import { first } from 'rxjs/operators';
import { ERROR_FEEDBACK_MAIL } from '../error.token';
import { OnDestroyMixin, untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';

@Component ( {
  selector   : 'ed-clients-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls  : ['./error-dialog.component.scss']
} )
export class ErrorDialogComponent extends OnDestroyMixin implements OnInit, AfterViewInit {
  num = 0;
  isFatal$: Observable<boolean>;
  mailto: string | undefined;
  @ViewChild ( 'desc' )
  private desc: ElementRef<HTMLDivElement>;

  constructor (
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
    @Inject ( MAT_DIALOG_DATA ) public data: ErrorCodeDetails,
    @Inject ( DOCUMENT ) private doc: Document,
    @Inject ( ERROR_FEEDBACK_MAIL ) private mail: string,
    store: Store,
    private ngZone: NgZone
  ) {
    super ();
    this.isFatal$ = store.select ( selectErrorIsFatalState );
  }

  onNoClick (): void {
    this.dialogRef.close();
  }

  close(){
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  // es gibt auch einige Regeln die einfach nur seltsam ist!
  // eslint-disable-next-line
  ngOnInit (): void {
  }

  ngAfterViewInit (): void {
    // eslint-disable-next-line max-len
    const m = `mailto:${this.mail}?subject=${encodeURIComponent ( 'Problemmeldung: ' + this.data.title )}&body=${encodeURIComponent ( 'Vielen Dank, dass Sie uns diesen Fehler übermitteln.\n\n\t' + this.desc.nativeElement.innerText + '\n\n Gerne können Sie hier noch weitere Details hinzufügen!' + '\n\nURL:' )}${this.doc.location.href}`;
    // wert wird sonst im Lifecycle 2 mal gesetzte
    interval ( 10 )
      .pipe ( first (), untilComponentDestroyed ( this ) )
      .subscribe (
        () => this.mailto = m
      );
  }
}
