import { RouterReducerState } from '@ngrx/router-store';
import { BlackbirdConfig } from './blackbird-config-store/blackbird-config.interface';
import { CommunicationState } from './communication-progress-store/communication.state';
import { RouterStateUrl } from './router-store/custom-serializer';

export interface RootStoreState {
  router?: RouterReducerState<RouterStateUrl>;
  com?: CommunicationState;
  blackbirdConfig?: BlackbirdConfig | undefined;
}
