import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';

import {Job, SwStoreResponse} from './shopware.interface';

// deprecated, only nettrek is using this resolver

@Injectable({
  providedIn: 'root'
})
export class JobsResolver implements Resolve<Job[]> {

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private transferState: TransferState,
              private http: HttpClient) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Job[]> {
    const contentSlug = 'all-jobs';
    const COURSE_KEY = makeStateKey<Job[]>(contentSlug);
    const environment = route.data.environment;

    if (this.transferState.hasKey(COURSE_KEY)) {
      const data = this.transferState.get<Job[]>(COURSE_KEY, []);
      return of(data);
    } else {
      const body = {
        includes: {product: ['id', 'name', 'customFields', 'description']},
        filter: [{type: 'equals', field: 'vendor.id', value: environment.shopware.vendorId}]
      };
      const options = {headers: {'sw-access-key': environment.shopware.accessKey}};

      return this.http.post<SwStoreResponse<Job>>(this.getJobsUrl(environment), body, options)
        .pipe(
          map(swRes => {
            return swRes.elements
          }),
          tap(loadedData => {
            this.transferState.set(COURSE_KEY, loadedData);
          })
        )
        ;
    }
  }

  private getJobsUrl(environment): string {
    let host = environment.browser.shopHost;
    if (isPlatformServer(this.platformId)) {
      host = environment.ssr.shopHost;
    }
    return host + environment.shopware.products;
  }
}
