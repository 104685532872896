import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MobileNavItem} from '../interfaces/mobile-nav-item';

@Component({
    selector: 'nt-mobile-section-item',
    templateUrl: './mobile-section-item.component.html',
    styleUrls: ['./mobile-section-item.component.scss']
})
export class MobileSectionItemComponent {

    @Input() mobileMenuData: MobileNavItem;
    @Output() clicked: EventEmitter<void> = new EventEmitter();

    mobileCloseFunction() {
        this.clicked.emit();
    }
}
