import {isPlatformServer} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {makeStateKey, TransferState} from '@angular/platform-browser';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {Content} from './content.interface';

interface BlackbirdResponse<T> {
  data: T
}

//the service is intended for shared data across project (shared components) or generic data that is not included in Content
//no metadata is set in this resolver.

@Injectable({
  providedIn: 'root'
})
export class GenericResolverService implements Resolve<any> {

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private transferState: TransferState,
    private http: HttpClient
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    // ToDo put into routing.module
    const slug = 'dwg-premium-config';

    const COURSE_KEY = makeStateKey<Content>(slug);


    if (this.transferState.hasKey(COURSE_KEY)) {
      const data = this.transferState.get<any>(COURSE_KEY, null);
      console.log('from Generic State', data);
      return of(data);
    } else {
      const token = route.data.environment.blackbird.token;
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.set('Accept', 'application/json');
      headers = headers.set('authorization', 'Bearer ' + token);
      return this.http.get<BlackbirdResponse<Content>>(this.getUrl(route.data.environment, slug), {
        headers: headers
      })
                 .pipe(
                   map(data => data.data),
                   tap(data => {
                     console.log('from Generic Blackbird', data);
                     this.transferState.set(COURSE_KEY, data);
                   }),
                   catchError((err) => {
                     if (err.status === 404) {
                       console.error(`Blackbird staticpage ${err.url} doesn't exist or is inavtive`);
                     }
                     console.log('Blackbird error: ', err);
                     return of({} as any)
                   })
                 );
    }
  }

  private getUrl(env, slug: string): string {
    let host = env.browser.jsonHost;
    if (isPlatformServer(this.platformId)) {
      host = env.ssr.jsonHost;
    }
    return host + `/${env.blackbird.content}/${slug}`;
  }
}
