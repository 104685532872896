// this function is used to convert a given string into tel or fax num, so that it can be used then
// in 'a' tag href
// It supports only german prefix!

export function convertIntoGermanTelOrFaxValue( telOrFaxText: string ): string {
  const telOrFaxValue = telOrFaxText.replace(/\D/g, '');

  if( telOrFaxValue.substr(0,2) === '49' ) {
    if( telOrFaxValue.charAt(2) === '0') { // 490... not allowed, remove zero
      return '+' + telOrFaxValue.replace('0', '') // first zero is replaced
    } else {
      return '+' + telOrFaxValue;
    }
  }

  if( telOrFaxValue.charAt(0) === '0' ) {
    return '+49' + telOrFaxValue.replace('0', '') // first zero is replaced
  }

  return '+49' + telOrFaxValue;
}
