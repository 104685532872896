<nav>
  <nt-app-nav></nt-app-nav>
</nav>

<main>
  <div *ngIf="metaInfo.schemas | async as schemas" class="schemas-box">
    <!--<p>{{schemas | json}}</p>-->
    <ngx-json-ld *ngFor="let schema of schemas" [json]="schema"></ngx-json-ld>
  </div>
  <!--
  <ngx-json-ld *ngIf="metaInfo.schema | async as schema" [json]="schema"></ngx-json-ld>
  -->
  <router-outlet></router-outlet>
</main>

<div class="footer">
  <div class="footer-top container">
    <div class="footer-company">
      <p style="font-weight: 700;">netTrek GmbH & Co. KG</p>
      <ul>
        <li>
          <a href="tel:+4923629994440" class="company-tel" onClick="gtag_report_conversion('tel:+4923629994440');">02362
            999 444 0</a>
        </li>
        <li>
          <a href="mailto:info@nettrek.de"
             onClick="gtag_report_conversion('mailto:info@nettrek.de');">info@nettrek.de</a>
        </li>
        <li>
          <a routerLink="kontakt">Kontakt</a>
        </li>
      </ul>
    </div>
    <div class="footer-links">
      <p style="font-weight: 700;">Interessante Links</p>
      <ul>
        <li>
          <a routerLink="unternehmenswerte">Unternehmenswerte</a>
        </li>
        <li>
          <a routerLink="projekte">Projekte</a>
        </li>
        <li>
          <a routerLink="softwareentwicklung">Softwareentwicklung</a>
        </li>
        <li>
          <a routerLink="design">Design & UX</a>
        </li>
        <li>
          <a routerLink="beratung">Beratung</a>
        </li>
        <li>
          <a routerLink="digitalisierung">Digitalisierung</a>
        </li>
        <li>
          <a href="https://e5-suite.de/" rel="noopener">E-Learning</a>
        </li>
      </ul>
    </div>
    <div class="footer-legal">
      <p style="font-weight: 700;">Rechtliche Informationen</p>
      <ul>
        <li>
          <a routerLink="datenschutzerklaerung">Datenschutzerklärung</a>
        </li>
        <li>
          <a routerLink="agb">Allgemeine Geschäftsbedingungen</a>
        </li>
        <li>
          <a routerLink="impressum">Impressum</a>
        </li>
      </ul>
    </div>
  </div>
  <hr>
  <div class="footer-sub-line container">
    <div class="left-side">
      <span class="copyright-span">&copy; netTrek</span>
      <span class="ingredients-span">Gemacht mit
        <ed-clients-lazy-picture [imgUri]="'/assets/image/herz.svg'" [alt]="'blaues-herz'" [width]="18" [height]="18"></ed-clients-lazy-picture>
                und der Hilfe von sehr viel
        <ed-clients-lazy-picture [imgUri]="'/assets/image/kaffee.svg'" [alt]="'kaffee'" [width]="18" [height]="18"></ed-clients-lazy-picture>
      </span>
    </div>
    <div class="right-side">
      <a href="https://www.facebook.com/netTrek.de" target="_blank" rel="noopener">
        <div class="social-icon icon-facebook">
         <ed-clients-lazy-picture [imgUri]="'/assets/image/facebook.svg'" [alt]="'facebook'" [width]="21" [height]="21"></ed-clients-lazy-picture>
        </div>
      </a>
      <a href="https://linkedin.com/company/nettrek-gmbh-und-co-kg" target="_blank" rel="noopener">
        <div class="social-icon icon-linkedin">
          <ed-clients-lazy-picture [imgUri]="'/assets/image/linked-in.svg'" [alt]="'linked-in'" [width]="21" [height]="21"></ed-clients-lazy-picture>
        </div>
      </a>
      <a href="https://www.instagram.com/nettrek.de/" target="_blank" rel="noopener">
        <div class="social-icon icon-instagram">
          <ed-clients-lazy-picture [imgUri]="'/assets/image/instagram.svg'" [alt]="'instagram'" [width]="21" [height]="21"></ed-clients-lazy-picture>
        </div>
      </a>
    </div>
  </div>
</div>
