<nav>
    <div class="container">
        <img class="logo" routerLink="" src="/assets/image/nettrek-logo.svg" alt="NetTrek-Logo">
        <div class="navigation-container">
            <div class="main-nav">
                <nt-desktop-nav-item
                    class="main-nav-item"
                    *ngFor="let navDataItem of navData.desktop"
                    [navDataItem]="navDataItem"
                    (customMouseEnter)="myShowFunction($event)"
                    (customMouseLeave)="myHideFunction()"
                ></nt-desktop-nav-item>
            </div>

            <div class="main-nav-item mobile-navigation">
                <div #mobileMenu id="mobile-menu">

                    <div (click)="mobileCloseFunction()" class="close-button"></div>
                    <div *ngFor="let mobileMenuData of navData.mobile.sections; index as i"
                         class="{{'section-' + (i + 1)}}"
                    >
                        <nt-mobile-section-item [mobileMenuData]="mobileMenuData"
                                                (clicked)="mobileCloseFunction()"
                        ></nt-mobile-section-item>
                    </div>
                </div>
                <div (click)="mobileOpenFunction()" class="open-mobile-menu"></div>
            </div>

        </div>
    </div>
</nav>
