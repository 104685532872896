import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

// use this pipe, for example, when iframe src is protected in angular and marked as unsafe
@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform { // eslint-disable-line

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(url: string ) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
