import { createFeatureSelector, createSelector } from '@ngrx/store';
import { projectsAdapter, ProjectsState } from './projects.state';

export const projectsFeatureKey  = 'projects';
export const selectProjectsState = createFeatureSelector<ProjectsState> ( projectsFeatureKey );

export const selectProjects = createSelector (
    selectProjectsState,
    projectsAdapter.getSelectors ().selectAll,
);

export const selectProjectsPagination = createSelector (
    selectProjectsState,
    ( state: ProjectsState ) => state.pagination,
);

export const selectProjectsByPage = createSelector (
    selectProjects,
    ( projects, page ) => {
        return projects.filter ( post => post.page === page );
    },
);

export const selectProjectsBySlug = createSelector (
    selectProjects,
    ( projects, slug ) => {
        return projects.filter ( post => post.slug === slug );
    },
);
