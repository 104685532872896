<ng-container *ngIf="!linkData.externalLink else externalLink">
    <a (click)="itemClicked();"
       [routerLink]="linkData.routerLink">
        <div class="popper-nav-element">
            <div class="popper-nav-element-text">
              <ed-clients-lazy-picture class="popper-nav-icon" [imgUri]="linkData.imgContent" [alt]="linkData.title" [width]="18" [height]="18"></ed-clients-lazy-picture>
                <span class="headline"
                [ngClass]="classToUse"
                >{{linkData.title}}</span>
            </div>
        </div>
    </a>
</ng-container>

<ng-template #externalLink>
    <a [href]="linkData.externalLink" target="_blank" rel="noopener">
        <div class="popper-nav-element">
            <div class="popper-nav-element-text">
              <ed-clients-lazy-picture class="popper-nav-icon" [imgUri]="linkData.imgContent" [alt]="linkData.title" [width]="18" [height]="18"></ed-clients-lazy-picture>
                <span class="headline"
                      [ngClass]="classToUse"
                >{{linkData.title}}</span>
            </div>
        </div>
    </a>

</ng-template>
