import { BlackbirdPagination, BlackbirdPost } from '@ed-clients/shared';
import { createAction, props } from '@ngrx/store';

export const PROJECTS_LOAD = createAction ( '[PROJECTS] LOAD PROJECTS',
  props<{ page?: number, limit?: number, query?: string }> ()
);

export const PROJECTS_SET = createAction ( '[PROJECTS] SET PROJECTS',
  props<{ projects: BlackbirdPost[] }> ()
);

export const PROJECTS_LAZY_LOADED_SET = createAction ( '[PROJECTS] LAZY LOADED SET PROJECTS',
  props<{ projects: BlackbirdPost[] }> ()
);

export const PROJECTS_SET_Pagination = createAction ( '[PROJECTS] SET PROJECTS PAGINATION',
  props<{ pagination: BlackbirdPagination }> ()
);
