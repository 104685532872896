<!--<div class="overlay" (click)="onOverlayClicked($event)">-->
<!--  <div class="dialog" (click)="onDialogClicked($event)">-->
<!--    <ng-template appInsertion></ng-template>-->
<!--  </div>-->
<!--</div>-->
<div class="overlay">
  <div class="dialog">
    <ng-template appInsertion></ng-template>
  </div>
</div>
