import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { VideosEffectsService } from './videos.effects.service';
import { videosReducer } from './videos.reducer';
import { videosFeatureKey } from './videos.selectors';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature ( videosFeatureKey, videosReducer ),
    EffectsModule.forFeature ( [VideosEffectsService] ),
  ]
})
export class VideosStoreModule { }
