import { Action, createReducer, on } from '@ngrx/store';
import { PROJECTS_LAZY_LOADED_SET, PROJECTS_SET, PROJECTS_SET_Pagination } from './projects.actions';
import { initialProjectsState, projectsAdapter, ProjectsState } from './projects.state';

const _projectsReducer = createReducer (
  initialProjectsState,
  on ( PROJECTS_SET, ( state, { projects } ) => projectsAdapter.setAll ( projects, state ) ),
  on ( PROJECTS_LAZY_LOADED_SET, ( state, { projects } ) => projectsAdapter.addMany ( projects, state ) ),
  on( PROJECTS_SET_Pagination, ( state, { pagination } ) =>
    ( { ...state, pagination } )
  )
);

export const projectsReducer = ( state: ProjectsState, action: Action ) => _projectsReducer ( state, action );
