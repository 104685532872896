export interface MetaData {
  seo?: Seo;
  socialMedia?: SocialMedia;
  schemas?: Schema[];
}

export interface Schema {
  '@context'?: string;
  '@type': string;
  description?: string;
  image?: string;
  title?: string;
  url?: string;
  [key: string]: any;
}

interface CommonSeoData {
  title?: string,
  description?: string;
  image?: string;
}

export interface Seo extends CommonSeoData {
  keywords?: string;
}

export interface SocialMedia extends CommonSeoData {
  twitterId?: string;
}
